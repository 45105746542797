import React, {Component, useState} from "react";
import "./ToolTextField.css";
import TextField from "@mui/material/TextField";

//Components

const ToolTextField = (props) => {

    return (
        <TextField { ...props } size="small" value={props.value} className={['tool-text-field animate', props.className].join(' ')}
                   sx={{
                       "& .MuiOutlinedInput-root": {
                           "&.Mui-focused fieldset": {
                               borderColor: "#4D79FF",
                           },
                       },
                       "& .MuiInputLabel-root.Mui-focused": {
                           color: "#4D79FF",
                       },
                   }}
        >
            {props.children}
        </TextField>
    )
}

export default ToolTextField;