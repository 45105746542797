import React, {Component} from 'react';
import './DocumentFileSystem.css';

//Components
import YesCancelDialog from "../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import Directory from "./Directory/Directory";
import api from "../../../../../../api";
import FileContextMenu from "../../../../../../reusable/FileContextMenu/FileContextMenu";
import File from "./File/File";
import FileSystemContextMenu from "../../../../../../reusable/FileSystemContextMenu/FileSystemContextMenu";
import DirectoryContextMenu from "../../../../../../reusable/DirectoryContextMenu/DirectoryContextMenu";
import CreateDirectoryDialog from "./CreateDirectoryDialog/CreateDirectoryDialog";
import CreateFileDialog from "./CreateFileDialog/CreateFileDialog";
import RenameFileDialog from "./RenameFileDialog/RenameFileDialog";
import RenameDirectoryDialog from "./RenameDirectoryDialog/RenameDirectoryDialog";
import PDFDialog from "../../../../../../reusable/PDFEditorDialog/PDFDialog";

class DocumentFileSystem extends Component {

    state = {
        activeDirectoryFile:null,
        isNewItem:true,
        rootDirectory:this.props.rootDirectory,
        indexArray:[],
        deleteIndex:null,
        formattedItems:[],
        activeParent:null,
        anchorPoint: {x:0,y:0},
        offsetPoint: {x:0,y:0},
        shownDirectory:this.props.rootDirectory,
        pathDirectories:[this.props.rootDirectory],

        //Dialog actions

        showDeleteDirectoryDialog:false,
        showDeleteFileDialog:false,
        showCreateDirectoryDialog:false,
        showCreateFileDialog:false,
        showRenameFileDialog:false,
        showRenameDirectoryDialog:false,

        //Context menu actions
        showFileSystemContextMenu:false,
        showFileContextMenu:false,
        showDirectoryContextMenu:false,
    }

    constructor(props) {
        super(props);
        this.documentFileSystemRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({rootDirectory:this.props.rootDirectory});
        }
    }

    componentDidMount = () => {
        window.addEventListener('click',this.closeAllContextMenus);
    }

    componentWillUnmount = () => {
        window.removeEventListener('click',this.closeAllContextMenus);
    }

    showFileSystemContextMenu = (e) => {
        if(this.state.showDirectoryContextMenu || this.state.showFileContextMenu) {

        }else{
            e.preventDefault();
            let anchorPoint = {
                x:e.pageX - this.props.documentActionsRef.current.offsetLeft - this.props.contentRef.current.offsetLeft,
                y:e.pageY - this.props.documentActionsRef.current.offsetTop - this.props.contentRef.current.offsetTop
            }
            this.setState({anchorPoint:anchorPoint,showFileSystemContextMenu:true});
        }
    }

    showDirectoryContextMenu = (e,directory) => {
        e.preventDefault();
        let anchorPoint = {
            x:e.pageX - this.props.documentActionsRef.current.offsetLeft - this.props.contentRef.current.offsetLeft,
            y:e.pageY - this.props.documentActionsRef.current.offsetTop - this.props.contentRef.current.offsetTop
        }
        this.setState({anchorPoint:anchorPoint,showDirectoryContextMenu:true,showFileSystemContextMenu:false,activeDirectoryFile:directory});
    }

    showFileContextMenu = (e,file) => {
        e.preventDefault();
        let anchorPoint = {
            x:e.pageX - this.props.documentActionsRef.current.offsetLeft - this.props.contentRef.current.offsetLeft,
            y:e.pageY - this.props.documentActionsRef.current.offsetTop - this.props.contentRef.current.offsetTop
        }
        this.setState({anchorPoint:anchorPoint,showFileContextMenu:true,showFileSystemContextMenu:false,activeDirectoryFile:file});
    }

    closeAllContextMenus = () => {
        this.closeFileSystemContextMenu();
        this.closeDirectoryContextMenu();
        this.closeFileContextMenu();
    }

    closeFileSystemContextMenu = () => {
        this.setState({showFileSystemContextMenu:false});
    }

    closeDirectoryContextMenu = () => {
        this.setState({showDirectoryContextMenu:false});
    }

    closeFileContextMenu = () => {
        this.setState({showFileContextMenu:false});
    }

    handleClick = (e,directory) => {
        if(e.detail === 2) {
            this.displayDirectoryContent(directory);
        }
    };

    displayDirectoryContent = (directory) => {
        let directories = this.state.pathDirectories;
        let exists = false;
        for(let i = 0; i < directories.length; i++) {
            if(directories[i].id === directory.id) {
                exists = true;
                directories.splice(i + 1);
                break;
            }
        }
        if(!exists) {
            directories.push(directory);
        }
        this.setState({shownDirectory:directory,directories:directories});
    }

    addDirectory = (directory, parent) => {
        let path = "";
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        let pathDirectoryRequest = {
            parentId:parent.id,
            directory:directory,
            path:path
        }
        let url = '/directories/directory';
        console.log(pathDirectoryRequest)
        api.put(url,pathDirectoryRequest)
            .then(response => {
                this.addDirectoryToList(response.data);
                this.closeCreateDirectoryDialog();
                this.props.showMessage(0,"Ordner erfolgreich angelegt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    addDirectoryToList = (directory) => {
        let shownDirectory = this.state.shownDirectory;
        shownDirectory.directories.push(directory);
        this.setState({shownDirectory:shownDirectory});
    }

    addFile = (multipartFile, parent) => {
        let path = "";
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        let url = '/directories/file';
        let formData = new FormData();
        formData.append("parentId",parent.id);
        formData.append("path",path);
        formData.append("multipartFile",multipartFile);
        formData.append("fileStorageLocationKind","OTHER")
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,'Datei erfolgreich angelegt');
                this.closeCreateFileDialog();
                this.addFileToList(response.data)
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    addFileToList = (file) => {
        let shownDirectory = this.state.shownDirectory;
        shownDirectory.fileStorageLocations.push(file);
        this.setState({shownDirectory:shownDirectory});
    }

    deleteDirectory = () => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        let pathDirectoryRequest = {
            path:path,
            directory:this.state.activeDirectoryFile
        }
        let url = '/directories/directory/remove';
        api.put(url,pathDirectoryRequest)
            .then(response => {
                this.removeDirectoryFromList(this.state.activeDirectoryFile);
                this.closeDeleteDirectoryDialog();
                this.props.showMessage(0,"Datei erfolgreich gelöscht");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    removeDirectoryFromList = (directory) => {
        let shownDirectory = this.state.shownDirectory;
        for(let i = 0; i < shownDirectory.directories.length; i++) {
            if(shownDirectory.directories[i].id === directory.id) {
                shownDirectory.directories.splice(i,1);
                break;
            }
        }
        this.setState({shownDirectory:shownDirectory});
    }

    deleteFile = (fileStorageLocation) => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        fileStorageLocation.path = path;
        let url = '/directories/file/remove';
        api.put(url,fileStorageLocation)
            .then(response => {
                this.removeFileFromList(this.state.activeDirectoryFile);
                this.closeDeleteFileDialog();
                this.props.showMessage(0,"Datei erfolgreich gelöscht");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    removeFileFromList = (fileStorageLocation) => {
        let shownDirectory = this.state.shownDirectory;
        for(let i = 0; i < shownDirectory.fileStorageLocations.length; i++) {
            if(shownDirectory.fileStorageLocations[i].id === fileStorageLocation.id) {
                shownDirectory.fileStorageLocations.splice(i,1);
                break;
            }
        }
        this.setState({shownDirectory:shownDirectory});
    }

    renameDirectory = (directory, newName) => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        let pathFileRequest = {
            newName:newName,
            path:path,
            directory:directory,
            setPathToNull:true
        }
        let url = '/directories/directory/rename/';
        api.put(url,pathFileRequest)
            .then(response => {
                let newDirectory = response.data;
                let shownDirectory = this.state.shownDirectory;
                for(let i = 0; i < shownDirectory.directories.length; i++) {
                    if(shownDirectory.directories[i].id === newDirectory.id) {
                        shownDirectory.directories[i] = newDirectory;
                        break;
                    }
                }
                this.setState({shownDirectory:shownDirectory});
                this.closeRenameDirectoryDialog();
                this.props.showMessage(0,"Datei erfolgreich umbenannt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    renameFile = (fileStorageLocation, newName) => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        fileStorageLocation.path = path;
        let pathFileRequest = {
            newName:newName,
            fileStorageLocation:fileStorageLocation,
            setPathToNull:true
        }
        let url = '/directories/file/rename/';
        api.put(url,pathFileRequest)
            .then(response => {
                let newFileStorageLocation = response.data;
                let shownDirectory = this.state.shownDirectory;
                for(let i = 0; i < shownDirectory.fileStorageLocations.length; i++) {
                    if(shownDirectory.fileStorageLocations[i].id === newFileStorageLocation.id) {
                        shownDirectory.fileStorageLocations[i] = newFileStorageLocation;
                        break;
                    }
                }
                this.setState({shownDirectory:shownDirectory});
                this.closeRenameFileDialog();
                this.props.showMessage(0,"Datei erfolgreich umbenannt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    displayFile = (fileStorageLocation) => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        fileStorageLocation.path = path;
        this.props.displayFile(fileStorageLocation);
    }

    addEmailAttachment = (fileStorageLocation,sendWarning) => {
        let path = '';
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        fileStorageLocation.path = path;
        this.props.addEmailAttachment(fileStorageLocation,sendWarning);
    }

    //Dialog actions

    showCreateDirectoryDialog = () => {
        this.setState({showCreateDirectoryDialog:true});
    }

    closeCreateDirectoryDialog = () => {
        this.setState({showCreateDirectoryDialog:false});
    }

    showCreateFileDialog = () => {
        this.setState({showCreateFileDialog:true});
    }

    closeCreateFileDialog = () => {
        this.setState({showCreateFileDialog:false});
    }

    showDeleteDirectoryDialog = (directory) => {
        this.setState({activeDirectoryFile:directory,showDeleteDirectoryDialog:true});
    }

    closeDeleteDirectoryDialog = () => {
        this.setState({showDeleteDirectoryDialog:false});
    }

    showDeleteFileDialog = (fileStorageLocation) => {
        this.setState({showDeleteFileDialog:true,activeDirectoryFile:fileStorageLocation});
    }

    closeDeleteFileDialog = () => {
        this.setState({showDeleteFileDialog:false});
    }

    showRenameFileDialog = (fileStorageLocation) => {
        this.setState({showRenameFileDialog:true,activeDirectoryFile:fileStorageLocation});
    }

    closeRenameFileDialog = () => {
        this.setState({showRenameFileDialog:false});
    }

    showRenameDirectoryDialog = (directory) => {
        this.setState({showRenameDirectoryDialog:true,activeDirectoryFile:directory});
    }

    closeRenameDirectoryDialog = () => {
        this.setState({showRenameDirectoryDialog:false});
    }

    showPDFDialog = (fileStorageLocation) => {
        let path = "";
        for(let i = 0; i < this.state.pathDirectories.length; i++) {
            path = path + "/" + this.state.pathDirectories[i].name;
        }
        fileStorageLocation.path = path;
        const url = '/file-storage';
        api.post(url,fileStorageLocation,{responseType:'blob'})
            .then(response => {
                console.log(response)
                const pdfFile = new Blob(
                    [response.data],
                    {type: response.data.type});
                pdfFile.name = fileStorageLocation.name;
                const pdfFileURL = URL.createObjectURL(pdfFile);
                this.setState({pdfFile:pdfFile,pdfFileURL:pdfFileURL,showPDFDialog:true});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                    console.log(error.response)
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    closePDFDialog = () => {
        this.setState({fileURL:null,showPDFDialog:false});
    }

    render() {
        return (
            <div ref={this.documentFileSystemRef} onContextMenu={(e) => this.showFileSystemContextMenu(e)} className='document-file-system'>
                <PDFDialog
                    open={this.state.showPDFDialog}
                    close={this.closePDFDialog}
                    showMessage={this.props.showMessage}
                    pdfFile={this.state.pdfFile}
                    pdfFileURL={this.state.pdfFileURL}
                />

                {this.state.showFileSystemContextMenu ? <FileSystemContextMenu
                    anchorPoint={this.state.anchorPoint}
                    parent={this.state.shownDirectory}
                    createDirectoryOnClickHandler={this.showCreateDirectoryDialog}
                    createFileOnClickHandler={this.showCreateFileDialog}
                /> : null}

                {this.state.showDirectoryContextMenu ? <DirectoryContextMenu
                    anchorPoint={this.state.anchorPoint}
                    parent={this.state.shownDirectory}
                    item={this.state.activeDirectoryFile}
                    renameDirectoryOnClickHandler={this.showRenameDirectoryDialog}
                    createDirectoryOnClickHandler={this.showCreateDirectoryDialog}
                    createFileOnClickHandler={this.showCreateFileDialog}
                    deleteDirectoryOnClickHandler={this.showDeleteDirectoryDialog}
                /> : null}

                {this.state.showFileContextMenu ? <FileContextMenu
                    anchorPoint={this.state.anchorPoint}
                    parent={this.state.shownDirectory}
                    createDirectoryOnClickHandler={this.showCreateDirectoryDialog}
                    createFileOnClickHandler={this.showCreateFileDialog}
                    addEmailAttachment={this.addEmailAttachment}
                    item={this.state.activeDirectoryFile}
                    renameFileOnClickHandler={this.showRenameFileDialog}
                    deleteFileOnClickHandler={this.showDeleteFileDialog}
                /> : null}

                <CreateFileDialog
                    open={this.state.showCreateFileDialog}
                    close={this.closeCreateFileDialog}
                    showMessage={this.props.showMessage}
                    addFile={this.addFile}
                    parent={this.state.shownDirectory}
                />

                <RenameDirectoryDialog
                    open={this.state.showRenameDirectoryDialog}
                    close={this.closeRenameDirectoryDialog}
                    showMessage={this.props.showMessage}
                    renameDirectory={this.renameDirectory}
                    item={this.state.activeDirectoryFile}
                />

                <RenameFileDialog
                    open={this.state.showRenameFileDialog}
                    close={this.closeRenameFileDialog}
                    showMessage={this.props.showMessage}
                    renameFile={this.renameFile}
                    item={this.state.activeDirectoryFile}
                />

                <CreateDirectoryDialog
                    open={this.state.showCreateDirectoryDialog}
                    close={this.closeCreateDirectoryDialog}
                    showMessage={this.props.showMessage}
                    addDirectory={this.addDirectory}
                    parent={this.state.shownDirectory}
                />

                <YesCancelDialog
                    open={this.state.showDeleteDirectoryDialog}
                    close={this.closeDeleteDirectoryDialog}
                    activeDirectoryFile={this.state.activeDirectoryFile}
                    showMessage={this.props.showMessage}
                    header={'Löschen'}
                    text={'Wollen Sie den Ordner wirklich löschen?'}
                    onClick={this.deleteDirectory}
                />

                <YesCancelDialog
                    open={this.state.showDeleteFileDialog}
                    close={this.closeDeleteFileDialog}
                    showMessage={this.props.showMessage}
                    header={'Löschen'}
                    text={'Wollen Sie das Dokument wirklich löschen?'}
                    onClick={() => this.deleteFile(this.state.activeDirectoryFile)}
                />

                <h1>Ordnerstruktur</h1>

                <ul className='directories-path'>
                    {this.state.pathDirectories.map((directory,index) => (
                        <li onClick={() => this.displayDirectoryContent(directory)} className='path-directory' key={directory.id}>{index === 0 ? "Ordnerstruktur" : directory.name} {index !== this.state.pathDirectories.length - 1 ? "/" : null}</li>
                    ))}
                </ul>

                <div className='file-tree'>
                    {/*<ToolAutocomplete onClick={() => this.showDirectoryFileDialog(blankDirectoryFile,null,true,[])} className='add-button'>+</ToolAutocomplete>*/}
                    {(this.state.shownDirectory.directories.length === 0 && this.state.shownDirectory.fileStorageLocations && this.state.shownDirectory.fileStorageLocations.length === 0) ?
                    <h3 className='directory-info'>Rechtsklick zum Anlegen eines Ordners oder einer Datei</h3> : null}
                    <div className='directory-holder'>
                        {this.state.shownDirectory.directories.map((item,index) => (
                            <Directory
                                key={index}
                                showMessage={this.props.showMessage}
                                index={index}
                                item={item}
                                edit={this.props.edit}
                                blankDirectoryFile={this.props.blankDirectoryFile}
                                showDirectoryFileDialog={this.props.showDirectoryFileDialog}
                                showDeleteFileDialog={this.props.showDeleteFileDialog}
                                addEntryToList={this.props.addEntryToList}
                                setActiveDirectoryFile={this.props.setActiveDirectoryFile}
                                parent={item}
                                formatString={this.props.formatString}
                                onContextMenu={this.showDirectoryContextMenu}
                                handleClick={this.handleClick}
                            />))}
                    </div>
                    <div className='file-holder'>
                        {this.state.shownDirectory && this.state.shownDirectory.fileStorageLocations ? this.state.shownDirectory.fileStorageLocations.map((item,index) => (
                            <File
                                key={index}
                                showMessage={this.props.showMessage}
                                index={index}
                                item={item}
                                edit={this.props.edit}
                                blankDirectoryFile={this.props.blankDirectoryFile}
                                showDirectoryFileDialog={this.props.showDirectoryFileDialog}
                                showDeleteFileDialog={this.props.showDeleteFileDialog}
                                addEntryToList={this.props.addEntryToList}
                                removeEntryFromList={this.props.removeEntryFromList}
                                parent={this.props.parent}
                                formatString={this.props.formatString}
                                showPDFDialog={this.showPDFDialog}
                                onContextMenu={this.showFileContextMenu}
                            />)) : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default DocumentFileSystem;