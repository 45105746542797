import React, {Component} from 'react';
import './WarehouseManagementSystem.css';

//Components
import ArticleInformation from "../CommonComponents/ArticleInformation/ArticleInformation";
import EmailPanel from "../CommonComponents/EmailPanel/EmailPanel";
import WarehouseActions from "./WarehouseActions/WarehouseActions";
import UserMenu from "../CommonComponents/UserMenu/UserMenu";
import WarehouseActionsMenu from "./WarehouseActionsMenu/WarehouseActionsMenu";
import SideMenu from "./WarehouseToolbar/SideMenu";
import LoadingScreen from "../CommonComponents/LoadingScreen/LoadingScreen";
import HistoryPanel from "../../../../reusable/HistoryPanel/HistoryPanel";
import Chat from "../../../../reusable/Chat/Chat";
import CommunicationActions from "../CommonComponents/CommunicationActions/CommunicationActions";

class WarehouseManagementSystem extends Component {

    componentDidMount() {
        let loadRequest = {
            loadFilters:this.props.filters === null,
            loadAccountPlan:this.props.accountPlan === null,
            loadArticleGroups:this.props.articleGroups === null,
            loadArticles:this.props.articles === null,
            loadAttributes:this.props.attributes === null,
            loadCustomers:this.props.customers === null,
            loadLocations:this.props.locations === null,
            loadSentEmails:this.props.sentEmails === null,
            loadPartsLists:this.props.partsLists === null,
            loadReservations:this.props.reservations === null,
            loadSuppliers:this.props.suppliers === null,
            loadUser:this.props.user === null,
            loadUsers:this.props.users === null,
            loadMessageTemplates:this.props.messageTemplates === null,
            loadBatches:this.props.batches === null
        }
        let letRecentDataLoadRequest = {
            loadTransactions:this.props.transactions === null,
            loadCustomerOrders:this.props.customerOrders === null,
            loadSupplierOrders:this.props.supplierOrders === null,
        }
        this.props.loadData(loadRequest,letRecentDataLoadRequest);
    }

    render() {

        let content;

        if( this.props.filters !== null &&
            this.props.accountPlan !== null &&
            this.props.articleGroups !== null &&
            this.props.articles !== null &&
            this.props.attributes !== null &&
            this.props.company !== null &&
            this.props.customers !== null &&
            this.props.locations !== null &&
            this.props.partsLists !== null &&
            this.props.sentEmails !== null &&
            this.props.reservations !== null &&
            this.props.suppliers !== null &&
            this.props.users !== null &&
            this.props.user !== null &&
            this.props.messageTemplates !== null &&
            this.props.batches !== null
        ) {
            content = <div className='surrounder'>
                <HistoryPanel
                    logout={this.props.logout}
                />
                <SideMenu
                    user={this.props.user}
                    company={this.props.company}
                />
                <div className='content'>

                    <UserMenu
                        //Data
                        user={this.props.user}
                        logout={this.props.logout}
                    />
                    <WarehouseActions
                        //Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        round={this.props.round}
                        generateDocument={this.props.generateDocument}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        //User
                        user={this.props.user}
                        loadUser={this.props.loadUser}
                        changeUsername={this.props.changeUsername}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}
                        logout={this.props.logout}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Transactions
                        transactions={this.props.transactions}
                        formattedTransactions={this.props.formattedTransactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        bookSupplierOrderInventoryTransactions={this.props.bookSupplierOrderInventoryTransactions}
                        updateTransactionInList={this.props.updateTransactionInList}

                        //Articles
                        articleInformation={this.props.articleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        getArticleInformation={this.props.getArticleInformation}
                        articles={this.props.articles}
                        addArticleToList={this.props.addArticleToList}
                        addNewArticle={this.props.addNewArticle}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}
                        updateMinimumStock={this.props.updateMinimumStock}

                        //Locations
                        locations={this.props.locations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}
                        addSupplierToList={this.props.addSupplierToList}

                        //Attributes
                        attributes={this.props.attributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Users
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        formattedCustomerOrders={this.props.formattedCustomerOrders}
                        addNewCustomerOrder={this.props.addNewCustomerOrder}
                        updateCustomerOrder={this.props.updateCustomerOrder}
                        deleteCustomerOrder={this.props.deleteCustomerOrder}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        addCustomerToList={this.props.addCustomerToList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        formattedSupplierOrders={this.props.formattedSupplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Email
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                        blinkBatch={this.props.blinkBatch}
                        setBlinkBatch={this.props.setBlinkBatch}
                    />
                    <EmailPanel
                        showMessage={this.props.showMessage}
                        formatString={this.props.formatString}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}

                        //User
                        user={this.props.user}

                        //Email
                        sendEmail={this.props.sendEmail}
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        clearEmailAttachments={this.props.clearEmailAttachments}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Sent Emails
                        sentEmails={this.props.sentEmails}
                        addSentEmailToList={this.props.addSentEmailToList}
                        removeSentEmailFromList={this.props.removeSentEmailFromList}

                        //Company
                        company={this.props.company}

                        //Customers
                        customers={this.props.customers}
                        suppliers={this.props.suppliers}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                    />
                    <ArticleInformation
                        user={this.props.user}
                        articleInformation={this.props.articleInformation}
                        batchInformation={this.props.batchInformation}
                        setLoading={this.props.setLoading}
                        blinkBatch={this.props.blinkBatch}
                        setBlinkBatch={this.props.setBlinkBatch}
                    />
                </div>
            </div>
        } else{
            content = <LoadingScreen/>
        }
        return (
            <div className='warehouse-management-system primary-color-background-very-light'>
                {content}
            </div>
        )
    }
}

export default WarehouseManagementSystem;