import React from 'react';
import './ModuleOverview.css';

// Components
import WarehouseManagementSystem from "./WarehouseManagementSystem/WarehouseManagementSystem";
import wm_logo from "./../../../images/wm_logo.png";
import am_logo from "./../../../images/am_logo.svg";
import dm_logo from "./../../../images/dm_logo.svg";
import blocked from "./../../../images/ban-solid.svg";
import { NavLink, Route } from "react-router-dom";
import AccountingManagementSystem from "./AccountingManagementSystem/AccountingManagementSystem";
import DocumentManagementSystem from "./DocumentManagementSystem/DocumentManagementSystem";
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router-dom';

const ModuleOverview = (props) => {

    const history = useHistory();

    return (
        <div className='module-overview primary-color-background-very-light'>
            <Tooltip id="module-overview-tooltip" />
            <Route exact path='/'>
                <ul className="modules">
                    <li>
                        {props.company && props.company.warehouseManager ?
                            <div className="module white-background active-glow-object"
                                 onClick={() => history.push("/warehouse-manager")}>
                                <span/>
                                <img className="module-icon" alt='Warenwirtschaft' src={wm_logo}/>
                                <h2 className="module-name">Warenwirtschaft</h2>
                            </div> :
                            <div className="module white-background deactivated-module deactivated-glow-object"
                                 onClick={() => props.showMessage(2, "Das Modul ist nicht aktiviert")}>
                                <span/>
                                <img className="module-icon" alt='Warenwirtschaft' src={wm_logo}/>
                                <h2 className="module-name">Warenwirtschaft</h2>
                            </div>}
                    </li>
                    <li>
                        {props.company && props.company.documentManager ?
                            <div className="module white-background active-glow-object"
                                 onClick={() => history.push("/document-manager")}>
                                <span/>
                                <img className="module-icon" alt='Dokumentenverwaltung' src={dm_logo}/>
                                <h2 className="module-name">Dokumentenverwaltung</h2>
                            </div> :
                            <div className="module white-background deactivated-module deactivated-glow-object"
                                 onClick={() => props.showMessage(2, "Das Modul ist nicht aktiviert")}>
                                <span/>
                                <img className="module-icon" alt='Dokumentenverwaltung' src={dm_logo}/>
                                <h2 className="module-name">Dokumentenverwaltung</h2>
                            </div>}
                    </li>
                    <li>
                        {props.company && props.company.accountingManager ?
                            <div className="module white-background active-glow-object"
                                 onClick={() => history.push("/accounting-manager")}>
                                <span/>
                                <img className="module-icon" alt='Buchhaltung' src={am_logo}/>
                                <h2 className="module-name">Buchhaltung</h2>
                            </div> :
                            <div className="module white-background deactivated-module deactivated-glow-object"
                                 onClick={() => props.showMessage(2, "Das Modul ist nicht aktiviert")}>
                                <span/>
                                <img className="module-icon" alt='Buchhaltung' src={am_logo}/>
                                <h2 className="module-name">Buchhaltung</h2>
                            </div>}
                    </li>
                </ul>
            </Route>
            <Route path='/warehouse-manager'>
                <WarehouseManagementSystem {...props} />
            </Route>
            <Route path='/accounting-manager'>
                <AccountingManagementSystem {...props} />
            </Route>
            <Route path='/document-manager'>
                <DocumentManagementSystem {...props} />
            </Route>
        </div>
    );
};

export default ModuleOverview;
