class HelperFunctions {
    static formatDateTime = (dateTime) => {
        return "am " + dateTime.substring(8, 10) + "." + dateTime.substring(5,7) + " um " + dateTime.substring(11,16) + " Uhr";
    }

    static round = (value, decimals) => {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }

    static formatString = (string,maxLength) => {
        let substring = string;
        if(string.length > maxLength) {
            substring = string.substring(0,maxLength) + "..."
        }
        return substring;
    }

    static formatDate = (date) => {
        try{
            return date.substring(8, 10) + "." + date.substring(5,7) + "." + date.substring(0, 4);
        }catch (e) {
            return "unbekanntes Datum"
        }
    }

    static dateToString = (date) => {
        return date.substring(8,10) + '.' + date.substring(5,7) + '.' + date.substring(0,4);
    }

    static generateRandomString = (length) => {
        const leftLimit = 48;
        const rightLimit = 122;
        let result = '';
        const characters = [];

        for (let i = leftLimit; i <= rightLimit; i++) {
            if ((i <= 57 || i >= 65) && (i <= 90 || i >= 97)) {
                characters.push(String.fromCharCode(i));
            }
        }

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }

        return result;
    }

    static dataURLtoFile = (dataurl,filename) => {
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    static formatCurrencyAmount = (currencyAmount) => {
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2
        });
        return formatter.format(currencyAmount);
    }

    static base64ToBlob(base64) {
        // Remove the data URL prefix
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        return new Blob([byteArray], { type: this.extractContentType(base64) });
    }

    static extractContentType(dataUrl) {
        const matches = dataUrl.match(/^data:(.*);base64,/);
        if (matches) {
            return matches[1];
        }
        throw new Error('Invalid data URL');
    }

    static addressEnumToString = (addressEnum) => {
        switch (addressEnum) {
            case "STANDARD":
                return "Standard";
            case "DELIVERY_BILL":
                return "Lieferschein";
            case "INVOICE":
                return "Rechnung";
            case "FACTORY_CERTIFICATE":
                return "Werkszeugnis";
            case "ORDER_CONFIRMATION":
                return "Auftragsbestätigung";
            case "ORDER":
                return "Bestellung";
            case "REQUEST":
                return "Anfrage";
            case "OFFER":
                return "Angebot";
            case "OTHER":
            default:
                return "Anderes";
        }
    }

    static getImageDimensionsFromBlob = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function(event) {
                const img = new Image();

                img.onload = function() {
                    resolve({ width: img.width, height: img.height });
                };

                img.onerror = function() {
                    reject(new Error('Error loading image'));
                };

                img.src = event.target.result;
            };

            reader.onerror = function() {
                reject(new Error('Error reading blob'));
            };

            reader.readAsDataURL(blob);
        });
    }

    static calculateLogoDimensions = (dimensions,maxWidth,maxHeight) => {
        let calculatedDimensions = dimensions;

        // Calculate the aspect ratio
        const aspectRatio = calculatedDimensions.width / calculatedDimensions.height;

        // Determine the new calculatedDimensions based on the aspect ratio
        if (calculatedDimensions.width > calculatedDimensions.height) {
            // Landscape orientation
            if (calculatedDimensions.width > maxWidth) {
                calculatedDimensions.width = maxWidth;
                calculatedDimensions.height = calculatedDimensions.width / aspectRatio;
            }
        } else {
            // Portrait or square orientation
            if (calculatedDimensions.height > maxHeight) {
                calculatedDimensions.height = maxHeight;
                calculatedDimensions.width = calculatedDimensions.height * aspectRatio;
            }
        }

        // If the image still doesn't fit, resize it again
        if (calculatedDimensions.width > maxWidth) {
            calculatedDimensions.width = maxWidth;
            calculatedDimensions.height = calculatedDimensions.width / aspectRatio;
        }

        if (calculatedDimensions.height > maxHeight) {
            calculatedDimensions.height = maxHeight;
            calculatedDimensions.width = calculatedDimensions.height * aspectRatio;
        }

        return calculatedDimensions;
    }

    static getArticleByString = (string,articles) => {
        if (string) {
            // Remove leading zeros from the input string
            const normalizedString = string.replace(/^0+/, '');

            for (let i = 0; i < articles.length; i++) {
                const article = articles[i];

                // Normalize article attributes by removing leading zeros for comparison
                const nr = article.nr ? article.nr.replace(/^0+/, '') : null;
                const ean = article.ean ? article.ean.replace(/^0+/, '') : null;
                const pcn = article.pcn ? article.pcn.replace(/^0+/, '') : null;
                const refNr = article.refNr ? article.refNr.replace(/^0+/, '') : null;

                if ((nr && (string === article.nr || normalizedString === nr)) ||
                    (ean && (string === article.ean || normalizedString === ean)) ||
                    (pcn && (string === article.pcn || normalizedString === pcn)) ||
                    (refNr && (string === article.refNr || normalizedString === refNr))) {
                    return article;
                }
            }
        }
        return null;
    }

    static getBatchByString = (string,batches) => {
        if (string) {
            // Remove leading zeros from the input string
            const normalizedString = string.replace(/^0+/, '');

            for (let i = 0; i < batches.length; i++) {
                const batch = batches[i];

                // Normalize batch attributes by removing leading zeros for comparison
                const nr = batch.nr ? batch.nr.replace(/^0+/, '') : null;
                const udi = batch.udi ? batch.udi.replace(/^0+/, '') : null;

                if ((nr && (string === batch.nr || normalizedString === nr)) ||
                    (udi && (string === batch.udi || normalizedString === udi))) {
                    return batch;
                }
            }
        }
        return null;
    }

    static getInformationByUDI = (udi,articles,batches) => {
        if(udi) {
            // first check
            if(udi.length >= 30 && udi.startsWith("01") && udi.includes("10") && udi.includes("17")) {
                let articleString = udi.substring(2,16);
                let batchString;
                let expirationString;

                let udiWithoutArticleString = udi.substring(16);

                if(!udiWithoutArticleString.includes("10") || !udiWithoutArticleString.includes("17")) {
                    return null;
                }

                // remove creation date
                if(udiWithoutArticleString.startsWith("11")) {
                    if(udiWithoutArticleString.startsWith("1120")) {
                        udiWithoutArticleString = udiWithoutArticleString.substring(10);
                    }else {
                        udiWithoutArticleString = udiWithoutArticleString.substring(8);
                    }
                }

                if(udiWithoutArticleString.startsWith("10")) { // Batchnr
                    let batchNrStartPosition = udiWithoutArticleString.indexOf("17",8)
                    let creationDateStartPosition = udiWithoutArticleString.indexOf("11",8)
                    if(batchNrStartPosition === -1) {
                        // es gibt keine batch nr
                        return null;
                    }

                    let udiWithoutArticleStringAndBatchNr;

                    if(creationDateStartPosition !== -1 && creationDateStartPosition < batchNrStartPosition) {
                        batchString = udiWithoutArticleString.substring(2,creationDateStartPosition);
                        udiWithoutArticleStringAndBatchNr = udiWithoutArticleString.substring(creationDateStartPosition);
                    }else{
                        batchString = udiWithoutArticleString.substring(2,batchNrStartPosition);
                        udiWithoutArticleStringAndBatchNr = udiWithoutArticleString.substring(batchNrStartPosition);
                    }

                    // remove creation date
                    if(udiWithoutArticleStringAndBatchNr.startsWith("11")) {
                        if(udiWithoutArticleStringAndBatchNr.startsWith("1120")) {
                            udiWithoutArticleStringAndBatchNr = udiWithoutArticleStringAndBatchNr.substring(10);
                        }else {
                            udiWithoutArticleStringAndBatchNr = udiWithoutArticleStringAndBatchNr.substring(8);
                        }
                    }

                    if(udiWithoutArticleStringAndBatchNr.startsWith("17")) { // expiration date
                        if(udiWithoutArticleStringAndBatchNr.startsWith("1720")) {
                            expirationString = udiWithoutArticleStringAndBatchNr.substring(2,10);
                        }else {
                            expirationString = "20" + udiWithoutArticleStringAndBatchNr.substring(2,8);
                        }
                        expirationString = expirationString.slice(0, 4) + '-' + expirationString.slice(4, 6) + '-' + expirationString.slice(6);
                        if(expirationString.substring(8,10) === "00") {
                            expirationString = expirationString.slice(0, 8) + '01';
                        }
                    }else{
                        return null;
                    }

                }else if(udiWithoutArticleString.startsWith("17")) { // Ablaufdatum
                    let udiWithoutArticleStringAndExpirationDate;
                    if(udiWithoutArticleString.startsWith("1720")) {
                        expirationString = udiWithoutArticleString.substring(2,10);
                        udiWithoutArticleStringAndExpirationDate = udiWithoutArticleString.substring(10);
                    }else{
                        expirationString = "20" + udiWithoutArticleString.substring(2,8);
                        udiWithoutArticleStringAndExpirationDate = udiWithoutArticleString.substring(8);
                    }
                    expirationString = expirationString.slice(0, 4) + '-' + expirationString.slice(4, 6) + '-' + expirationString.slice(6);
                    if(expirationString.substring(8,10) === "00") {
                        expirationString = expirationString.slice(0, 8) + '01';
                    }
                    // remove creation date
                    if(udiWithoutArticleStringAndExpirationDate.startsWith("11")) {
                        if(udiWithoutArticleStringAndExpirationDate.startsWith("1120")) {
                            udiWithoutArticleStringAndExpirationDate = udiWithoutArticleStringAndExpirationDate.substring(10);
                        }else {
                            udiWithoutArticleStringAndExpirationDate = udiWithoutArticleStringAndExpirationDate.substring(8);
                        }
                    }
                    if(udiWithoutArticleStringAndExpirationDate.startsWith("10")) {
                        batchString = udiWithoutArticleStringAndExpirationDate.substring(2);
                    }
                }else{
                    return null;
                }

                if (articleString && batchString && expirationString) {
                    let article;
                    let batch;

                    article = HelperFunctions.getArticleByString(articleString, articles);

                    if (!article) {
                        article = {
                            nr: articleString
                        }
                    }

                    batch = HelperFunctions.getBatchByString(batchString, batches);

                    if (!batch) {
                        batch = {
                            nr: batchString,
                            expirationDate: expirationString,
                            udi: udi
                        }
                    }

                    return {
                        article: article,
                        batch: batch
                    };
                }
                return null;
            }
            return null;
        }else{
            return null;
        }
    }

    static getCountryCodeByCountry = (selectedCountry) => {
        switch (selectedCountry) {
            case 'Deutschland':
                return 'DE';
            case 'USA':
                return 'US';
            case 'Frankreich':
                return 'FR';
            case 'England':
                return 'GB';
            case 'Niederlande':
                return 'NL';
            case 'China':
                return 'CN';
            case 'Österreich':
                return 'AT';
            case 'Italien':
            case 'italien':
                return 'IT';
            case 'Polen':
                return 'PL';
            case 'Schweiz':
                return 'CH';
            case 'Belgien':
                return 'BE';
            case 'Spanien':
                return 'ES';
            case 'Tschechien':
                return 'CZ';
            case 'Schweden':
                return 'SE';
            case 'Türkei':
                return 'TR';
            case 'Ungarn':
                return 'HU';
            case 'Afghanistan':
                return 'AF';
            case 'Ägypten':
                return 'EG';
            case 'Albanien':
                return 'AL';
            case 'Algerien':
                return 'DZ';
            case 'Andorra':
                return 'AD';
            case 'Angola':
                return 'AO';
            case 'Antigua und Barbuda':
                return 'AG';
            case 'Äquatorialguinea':
                return 'GQ';
            case 'Argentinien':
                return 'AR';
            case 'Armenien':
                return 'AM';
            case 'Aserbaidschan':
                return 'AZ';
            case 'Äthiopien':
                return 'ET';
            case 'Australien':
                return 'AU';
            case 'Bahamas':
                return 'BS';
            case 'Bahrain':
                return 'BH';
            case 'Bangladesch':
                return 'BD';
            case 'Barbados':
                return 'BB';
            case 'Belarus':
                return 'BY';
            case 'Belize':
                return 'BZ';
            case 'Benin':
                return 'BJ';
            case 'Bhutan':
                return 'BT';
            case 'Bolivien':
                return 'BO';
            case 'Bosnien und Herzegowina':
                return 'BA';
            case 'Botsuana':
                return 'BW';
            case 'Brasilien':
                return 'BR';
            case 'Brunei Darussalam':
                return 'BN';
            case 'Bulgarien':
                return 'BG';
            case 'Burkina Faso':
                return 'BF';
            case 'Burundi':
                return 'BI';
            case 'Cabo Verde':
                return 'CV';
            case 'Chile':
                return 'CL';
            case 'Cookinseln':
                return 'CK';
            case 'Costa Rica':
                return 'CR';
            case 'Cote d Ivoire':
                return 'CI';
            case 'Dänemark':
                return 'DK';
            case 'Dominica':
                return 'DM';
            case 'Dominikanische Republik':
                return 'DO';
            case 'Dschibuti':
                return 'DJ';
            case 'Ecuador':
                return 'EC';
            case 'El Salvador':
                return 'SV';
            case 'Eritrea':
                return 'ER';
            case 'Estland':
                return 'EE';
            case 'Eswatini':
                return 'SZ';
            case 'Fidschi':
                return 'FJ';
            case 'Finnland':
                return 'FI';
            case 'Gabun':
                return 'GA';
            case 'Gambia':
                return 'GM';
            case 'Georgien':
                return 'GE';
            case 'Ghana':
                return 'GH';
            case 'Grenada':
                return 'GD';
            case 'Griechenland':
                return 'GR';
            case 'Guatemala':
                return 'GT';
            case 'Guinea':
                return 'GN';
            case 'Guinea-Bissau':
                return 'GW';
            case 'Guyana':
                return 'GY';
            case 'Haiti':
                return 'HT';
            case 'Heiliger Stuhl':
                return 'VA';
            case 'Honduras':
                return 'HN';
            case 'Indien':
                return 'IN';
            case 'Indonesien':
                return 'ID';
            case 'Irak':
                return 'IQ';
            case 'Iran':
                return 'IR';
            case 'Irland':
                return 'IE';
            case 'Island':
                return 'IS';
            case 'Israel':
                return 'IL';
            case 'Jamaika':
                return 'JM';
            case 'Japan':
                return 'JP';
            case 'Jemen':
                return 'YE';
            case 'Jordanien':
                return 'JO';
            case 'Kambodscha':
                return 'KH';
            case 'Kamerun':
                return 'CM';
            case 'Kanada':
                return 'CA';
            case 'Kasachstan':
                return 'KZ';
            case 'Katar':
                return 'QA';
            case 'Kenia':
                return 'KE';
            case 'Kirgisistan':
                return 'KG';
            case 'Kiribati':
                return 'KI';
            case 'Kolumbien':
                return 'CO';
            case 'Komoren':
                return 'KM';
            case 'Kongo':
                return 'CG';
            case 'Korea':
                return 'KR';
            case 'Kosovo':
                return 'XK';
            case 'Kroatien':
                return 'HR';
            case 'Kuba':
                return 'CU';
            case 'Kuwait':
                return 'KW';
            case 'Laos':
                return 'LA';
            case 'Lesotho':
                return 'LS';
            case 'Lettland':
                return 'LV';
            case 'Libanon':
                return 'LB';
            case 'Liberia':
                return 'LR';
            case 'Libyen':
                return 'LY';
            case 'Liechtenstein':
                return 'LI';
            case 'Litauen':
                return 'LT';
            case 'Luxemburg':
                return 'LU';
            case 'Madagaskar':
                return 'MG';
            case 'Malawi':
                return 'MW';
            case 'Malaysia':
                return 'MY';
            case 'Malediven':
                return 'MV';
            case 'Mali':
                return 'ML';
            case 'Malta':
                return 'MT';
            case 'Marokko':
                return 'MA';
            case 'Marshallinseln':
                return 'MH';
            case 'Mauretanien':
                return 'MR';
            case 'Mexiko':
                return 'MX';
            case 'Mikronesien':
                return 'FM';
            case 'Moldau':
                return 'MD';
            case 'Monaco':
                return 'MC';
            case 'Mongolei':
                return 'MN';
            case 'Montenegro':
                return 'ME';
            case 'Mosambik':
                return 'MZ';
            case 'Namibia':
                return 'NA';
            case 'Nauru':
                return 'NR';
            case 'Nepal':
                return 'NP';
            case 'Neuseeland':
                return 'NZ';
            case 'Nicaragua':
                return 'NI';
            case 'Niger':
                return 'NE';
            case 'Nigeria':
                return 'NG';
            case 'Niue':
                return 'NU';
            case 'Nordmazedonien':
                return 'MK';
            case 'Norwegen':
                return 'NO';
            case 'Oman':
                return 'OM';
            case 'Pakistan':
                return 'PK';
            case 'Palau':
                return 'PW';
            case 'Panama':
                return 'PA';
            case 'Papua-Neuguinea':
                return 'PG';
            case 'Paraguay':
                return 'PY';
            case 'Peru':
                return 'PE';
            case 'Philippinen':
                return 'PH';
            case 'Portugal':
                return 'PT';
            case 'Ruanda':
                return 'RW';
            case 'Rumänien':
                return 'RO';
            case 'Russland':
                return 'RU';
            case 'Salomonen':
                return 'SB';
            case 'Sambia':
                return 'ZM';
            case 'Samoa':
                return 'WS';
            case 'San Marino':
                return 'SM';
            case 'São Tomé und Príncipe':
                return 'ST';
            case 'Saudi-Arabien':
                return 'SA';
            case 'Schottland':
                return 'GB';
            case 'Senegal':
                return 'SN';
            case 'Serbien':
                return 'RS';
            case 'Seychellen':
                return 'SC';
            case 'Sierra Leone':
                return 'SL';
            case 'Simbabwe':
                return 'ZW';
            case 'Singapur':
                return 'SG';
            case 'Slowakei':
                return 'SK';
            case 'Slowenien':
                return 'SI';
            case 'Somalia':
                return 'SO';
            case 'Sri Lanka':
                return 'LK';
            case 'St. Kitts und Nevis':
                return 'KN';
            case 'St. Lucia':
                return 'LC';
            case 'St. Vincent und die Grenadinen':
                return 'VC';
            case 'Südafrika':
                return 'ZA';
            case 'Sudan':
                return 'SD';
            case 'Südsudan':
                return 'SS';
            case 'Suriname':
                return 'SR';
            case 'Syrien':
                return 'SY';
            case 'Tadschikistan':
                return 'TJ';
            case 'Tansania':
                return 'TZ';
            case 'Thailand':
                return 'TH';
            case 'Timor-Leste':
                return 'TL';
            case 'Togo':
                return 'TG';
            case 'Tonga':
                return 'TO';
            case 'Trinidad und Tobago':
                return 'TT';
            case 'Tschad':
                return 'TD';
            case 'Tunesien':
                return 'TN';
            case 'Turkmenistan':
                return 'TM';
            case 'Tuvalu':
                return 'TV';
            case 'Uganda':
                return 'UG';
            case 'Ukraine':
                return 'UA';
            case 'Uruguay':
                return 'UY';
            case 'Usbekistan':
                return 'UZ';
            case 'Vanuatu':
                return 'VU';
            case 'Vatikanstadt':
                return 'VA';
            case 'Venezuela':
                return 'VE';
            case 'Vereinigte Arabische Emirate':
                return 'AE';
            case 'Vietnam':
                return 'VN';
            case 'Wales':
                return 'GB';
            case 'Weißrussland':
                return 'BY';
            case 'Zentralafrikanische Republik':
                return 'CF';
            case 'Zypern':
                return 'CY';
            default:
                return '';
        }
    }

}

export default HelperFunctions;