import React, { useState, useEffect } from 'react';
import './BatchItem.css';

// Components
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import Paper from '@mui/material/Paper';
import HelperFunctions from "../HelperFunctions";
import ToolPaper from "../ToolPaper/ToolPaper";
import {Tooltip} from "react-tooltip";

const BatchItem = (props) => {

    const _handleDoubleClick = () => {
        if(props.showBatchDialog) {
            props.showBatchDialog();
        }
    }

    return (
        <ToolPaper onDoubleClick={_handleDoubleClick} elevation={3} className='batch-item'>

            <DeleteIcon
                onClick={() => props.deleteBatch(props.batch)}
                className='icon delete-icon'
            />
            <ArticleIcon
                onClick={() => props.showBatchDialog(props.batch)}
                className='icon edit-icon'
            />

            <ul className="information-holder">
                <li className="option-li">{props.batch && props.batch.nr ? HelperFunctions.formatString(props.batch.nr, 12) : "-"}</li>
                <li className="option-li">{props.batch && props.batch.expirationDate ? HelperFunctions.formatDate(props.batch.expirationDate) : "-"}</li>
                <li className="option-li">{props.batch && props.batch.material ? HelperFunctions.formatString(props.batch.material, 12) : "-"}</li>
                <li className="option-li">
                    {props.batch && props.batch.batchInventories ? props.batch.batchInventories.map(batchInventory => (
                        <li className="multiple-holder-li">
                            <p>{batchInventory.inventoryCountFree ? batchInventory.inventoryCountFree : "-"}</p>
                        </li>
                    )) : null}
                </li>
                <li className="option-li">
                    {props.batch && props.batch.batchInventories ? props.batch.batchInventories.map(batchInventory => (
                        <li className="multiple-holder-li"><p
                            data-tooltip-id="batches-tooltip"
                            data-tooltip-place="top"
                            data-tooltip-content={batchInventory.inventory && batchInventory.inventory.article && batchInventory.inventory.article.nr && batchInventory.inventory.article.nr.length > 12 ? batchInventory.inventory.article.nr : ""}
                        >{batchInventory.inventory && batchInventory.inventory.article && batchInventory.inventory.article.nr ? HelperFunctions.formatString(batchInventory.inventory.article.nr, 12) : "-"}</p>
                        </li>
                    )) : null}
                </li>
                <li className="option-li">
                    {props.batch && props.batch.batchInventories ? props.batch.batchInventories.map(batchInventory => (
                        <li className="multiple-holder-li">
                            <p
                                data-tooltip-id="batches-tooltip"
                                data-tooltip-place="top"
                                data-tooltip-content={batchInventory.inventory && batchInventory.inventory.article && batchInventory.inventory.article.name && batchInventory.inventory.article.name.length > 12 ? batchInventory.inventory.article.name : ""}
                            >{batchInventory.inventory && batchInventory.inventory.article && batchInventory.inventory.article.name ? HelperFunctions.formatString(batchInventory.inventory.article.name, 12) : "-"}</p>
                        </li>
                    )) : null}
                </li>
            </ul>
        </ToolPaper>
    );
};

export default BatchItem;