import React, {Component} from 'react';
import './LoadingScreen.css';

class LoadingScreen extends Component {

    render() {
        return (
            <div className="loading-screen primary-color-background-very-light">
                <div className="loading-pulse"/>
            </div>
        )
    }
}

export default LoadingScreen;