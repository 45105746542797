import React, {Component} from "react";
import './AccountingToolbar.css';

//Components
import am_logo from '../../../../../images/am_logo.svg';
import ArrowBack from "@mui/icons-material/ArrowBack";
import {NavLink} from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import StorageIcon from "@mui/icons-material/Storage";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SettingsIcon from "@mui/icons-material/Settings";

class AccountingToolbar extends Component {

    state = {
        isMenuShown:false
    }

    toggleMenu = () => {
        this.setState({isMenuShown:!this.state.isMenuShown});
    }

    render() {

        let style;

        if(this.state.isMenuShown) {
            style = {
                width:'256px'
            }
        }else{
            style = {
                width:'0'
            }
        }

        return (
            <div className='accounting-toolbar'>
                <div className='top-panel'>
                    <i onClick={this.toggleMenu} className='fas fa-bars'/>
                </div>
                <div style={this.state.isMenuShown ? style : null} className='side-menu'>
                    <div className='logo-holder'>
                        <img src={am_logo} alt="Warehouse Manager 4.0 Logo"/>
                    </div>
                    <menu className='navigation-items'>
                        <NavLink className='nav-item' activeClassName='active' exact to='/accounting-manager'><h2>
                            <DashboardIcon className='icon'/> Übersicht</h2></NavLink>
                        <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/accounting'><h2>
                            <MenuBookIcon className='icon'/> Buchhaltung</h2></NavLink>
                        <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/master-data'><h2>
                            <StorageIcon className='icon'/> Stammdaten</h2></NavLink>
                        {this.props.user ? this.props.user.masterUser ?
                            <NavLink className='nav-item' activeClassName='active'
                                     to='/accounting-manager/administration'><h2><SupervisorAccountIcon
                                className='icon'/> Administration</h2></NavLink> : null : null}
                        <NavLink className='nav-item' activeClassName='active' to='/accounting-manager/settings'><h2>
                            <SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
                    </menu>
                    <NavLink exact to='/'>
                        <div className="icon-holder">
                            <ArrowBack className='icon arrow-icon animate'/>
                        </div>
                    </NavLink>
                </div>
            </div>
        )
    }
}


export default AccountingToolbar;