import React, {Component} from 'react';
import './UserAdministration.css';

//Components
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@mui/icons-material/Add';
import UserDialog from "./UserDialog/UserDialog";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import UserItem from "./UserItem/UserItem";

class UserAdministration extends Component {

    state = {
        selectedUser:null,
        action:null,
        user: {
            username:'',
            password:'',
            assignedLocation: null
        },
        isNewUser:true,

        //Dialogs
        showUserDialog:false,
        showDeleteUserDialog:false
    }

    //Dialog actions
    showUserDialog = (isNewUser,user) => {
        this.setState({isNewUser:isNewUser,user:user,showUserDialog:true});
    }

    closeUserDialog = () => {
        this.setState({showUserDialog:false});
    }

    showDeleteUserDialog = (user) => {
        this.setState({user:user,showDeleteUserDialog:true});
    }

    closeDeleteUserDialog = () => {
        this.setState({showDeleteUserDialog:false});
    }

    render() {

        const plankUser = {
            username:'',
            password:'',
            assignedLocation:null
        }

        return (
            <div className='user-administration'>

                <UserDialog
                    open={this.state.showUserDialog}
                    close={this.closeUserDialog}
                    addNewUser={this.props.addNewUser}
                    showMessage={this.props.showMessage}
                    user={this.state.user}
                    isNewUser={this.state.isNewUser}
                    locations={this.props.locations}
                />

                <YesCancelDialog
                    open={this.state.showDeleteUserDialog}
                    close={this.closeDeleteUserDialog}Po
                    header='Löschen'
                    text={'Wollen Sie den Benutzer wirklich löschen?'}
                    onClick={() => this.props.deleteUser(this.state.user)}
                />
                <div className='item-list'>
                    {this.props.users.map((user,index) => (
                        <UserItem
                            key={index}
                            user={user}
                            showUserDialog={this.showUserDialog}
                            deleteUser={() => this.showDeleteUserDialog(user)}
                            locations={this.props.locations}
                            changePassword={this.props.changePassword}
                            changeAssignedLocation={this.props.changeAssignedLocation}
                            changeOwnPassword={this.props.changePassword}
                            showMessage={this.props.showMessage}
                        />
                    ))}
                </div>
                <ToolFab className='add-fab' onClick={() => this.showUserDialog(true,plankUser)} aria-label="add">
                    <AddIcon />
                </ToolFab>
            </div>
        )
    }
}

export default UserAdministration;