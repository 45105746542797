import React, {Component} from "react";
import './Settings.css';

//Components
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import {Route} from "react-router-dom";
import api from "../../../../../../api";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToolTextField from "../../../../../../reusable/ToolField/ToolTextField";

class Settings extends Component {

    state = {
        changeOwnPasswordRequest:null,
        user:this.props.user
    }

    oldPasswordOnChangeHandler = (e) => {
        let changeOwnPasswordRequest;
        if(this.state.changeOwnPasswordRequest) {
            changeOwnPasswordRequest = this.state.changeOwnPasswordRequest;
        }else{
            changeOwnPasswordRequest = {};
        }
        changeOwnPasswordRequest.oldPassword = e.target.value;
        this.setState({changeOwnPasswordRequest:changeOwnPasswordRequest});
    }

    newPasswordOnChangeHandler = (e) => {
        let changeOwnPasswordRequest;
        if(this.state.changeOwnPasswordRequest) {
            changeOwnPasswordRequest = this.state.changeOwnPasswordRequest;
        }else{
            changeOwnPasswordRequest = {};
        }
        changeOwnPasswordRequest.newPassword = e.target.value;
        this.setState({changeOwnPasswordRequest:changeOwnPasswordRequest});
    }

    checkPasswordOnChangeHandler = (e) => {
        let changeOwnPasswordRequest;
        if(this.state.changeOwnPasswordRequest) {
            changeOwnPasswordRequest = this.state.changeOwnPasswordRequest;
        }else{
            changeOwnPasswordRequest = {};
        }
        changeOwnPasswordRequest.checkPassword = e.target.value;
        this.setState({changeOwnPasswordRequest:changeOwnPasswordRequest});
    }

    changeOwnPasswordOnClickHandler = () => {
        if(this.state.changeOwnPasswordRequest && this.state.changeOwnPasswordRequest.newPassword !== '' && this.state.changeOwnPasswordRequest.checkPassword !== '') {
            if(this.state.changeOwnPasswordRequest.newPassword === this.state.changeOwnPasswordRequest.checkPassword) {
                this.props.changeOwnPassword(this.state.changeOwnPasswordRequest);
            }else{
                this.props.showMessage(2,'Das wiederholte Passwort entspricht nicht dem neuen Passwort');
            }
        }else{
            this.props.showMessage(2,'Bitte alle Felder ausfüllen');
        }
    }

    clearFields = () => {
        this.setState({changeOwnPasswordRequest:null});
    }

    toggleExtendedFunctionalities = () => {
        let user = this.state.user;
        user.userInformation.extendedFunctionalities = !user.userInformation.extendedFunctionalities;
        this.setState({user:user});
    }

    defaultMessageOnChangeHandler = (e) => {
        let user = this.state.user;
        if(!user.userInformation) {
            user.userInformation = {};
        }
        user.userInformation.emailDefaultMessage = e.target.value;
        this.setState({user:user});
    }

    signatureOnChangeHandler = (e) => {
        let user = this.state.user;
        if(!user.userInformation) {
            user.userInformation = {};
        }
        user.userInformation.emailSignature = e.target.value;
        this.setState({user:user});
    }

    updateUserInformationOnClickHandler = () => {
        this.props.updateUserInformation(this.state.user.userInformation);
    }

    render() {

        let bookText;
        if(this.state.user.userInformation.extendedFunctionalities) {
            bookText = <p className='SwitchText'>Erweiterte Funktionen aktiviert</p>
        }else{
            bookText = <p className='SwitchText'>Erweiterte Funktionen deaktiviert</p>
        }

        return (
            <div className='settings'>
                <Route exact path='/*/settings'>
                    <FormControlLabel
                        className='input'
                        control={
                            <Switch
                                checked={this.state.user.userInformation.extendedFunctionalities}
                                onChange={this.toggleExtendedFunctionalities}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={bookText}
                    />
                    <ToolButton onClick={this.updateUserInformationOnClickHandler} main className='save-button'>Speichern</ToolButton>
                </Route>
                <Route exact path='/*/settings/security'>
                    <ToolTextField size="small" value={this.state.changeOwnPasswordRequest ? this.state.changeOwnPasswordRequest.oldPassword : null} onChange={this.oldPasswordOnChangeHandler} type='password' label='Altes Passwort' className='input'/>
                    <ToolTextField size="small" value={this.state.changeOwnPasswordRequest ? this.state.changeOwnPasswordRequest.newPassword : null} onChange={this.newPasswordOnChangeHandler} type='password' label='Neues Passwort' className='input'/>
                    <ToolTextField size="small" value={this.state.changeOwnPasswordRequest ? this.state.changeOwnPasswordRequest.checkPassword : null} onChange={this.checkPasswordOnChangeHandler} type='password' label='Passwort wiederholen' className='input'/>
                    <ToolButton onClick={this.changeOwnPasswordOnClickHandler} main className='save-button'>Speichern</ToolButton>
                </Route>
            </div>
        )
    }
}

export default Settings;