import React, { useState, useEffect, useRef } from 'react';
import './ArticleMovement.css';

//Components
import api from "../../../../../../../api";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import { Tooltip } from 'react-tooltip'
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import TransactionItem from "../../../../../../../reusable/TransactionItem/TransactionItem";
import TransactionDialog from "../../../../../../../reusable/TransactionDialog/TransactionDialog";
import SecureYesCancelDialog from "../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import SearchIcon from '@mui/icons-material/Search';
import SearchTransactionDialog from "./SearchTransactionDialog/SearchTransactionDialog";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ImportDataDialog from "../../../../../../../reusable/ImportDataDialog/ImportDataDialog";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ToolTextField from "../../../../../../../reusable/ToolField/ToolTextField";
import CircularProgress from "@mui/material/CircularProgress";

const ArticleMovement = (props) => {

    const filter = createFilterOptions();

    const [bookIn, setBookIn] = useState(false);
    const [article, setArticle] = useState(null);
    const [batch, setBatch] = useState(null);
    const [inventoryCount, setInventoryCount] = useState("");
    const [toLocation, setToLocation] = useState(null);
    const [assignedCustomer, setAssignedCustomer] = useState(null);
    const [assignedSupplier, setAssignedSupplier] = useState(null);
    const [infoText,setInfoText] = useState(null);
    const [activeTransaction, setActiveTransaction] = useState(null);
    const [articleBatches, setArticleBatches] = useState(null);
    const [transactionsActive, setTransactionsActive] = useState(null);
    const [searchWord, setSearchWord] = useState(null);
    const [filteredTransactions, setFilteredTransactions] = useState(null);

    const [inventoryInfoDialog, setInventoryInfoDialog] = useState(false);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [searchTransactionDialog,setSearchTransactionDialog] = useState(false);
    const [updateMinimumStockDialog,setUpdateMinimumStockDialog] = useState(false);

    const customerRef = useRef(null);
    const articleRef = useRef(null);
    const batchRef = useRef(null);
    const inventoryCountRef = useRef(null);

    useEffect(() => {
        loadTransactionsActive();
    }, []);

    const searchWordOnChangeHandler = (e) => {
        let input = e.target.value;
        if(input) {
            let filteredTransactions = [];
            for(let i = 0; i < props.transactions.length; i++) {
                if(
                    (props.transactions[i].article?.id?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].article?.nr?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].article?.name?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].article?.ref?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].article?.ean?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].article?.pcn?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].batch?.nr?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].assignedCustomer?.nr?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].assignedCustomer?.name?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].assignedSupplier?.nr?.toString().toLowerCase().includes(input.toLowerCase())) ||
                    (props.transactions[i].assignedSupplier?.name?.toString().toLowerCase().includes(input.toLowerCase()))
                ) {
                    filteredTransactions.push(props.transactions[i]);
                }
            }
            setFilteredTransactions(filteredTransactions);
        }else{
            setFilteredTransactions(null);
        }
        setSearchWord(e.target.value);
    }

    const loadTransactionsActive = () => {
        props.setLoading(true);
        api.get("/locations/lock/transactions")
            .then(response => {
                setTransactionsActive(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    };

    const updateTransactionsActive = (locked) => {
        props.setLoading(true);
        api.put("/locations/lock/transactions", locked, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setTransactionsActive(response.data);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            switch (e.target.id) {
                case "inventoryCount":
                    bookTransactionOnClickHandler();
                    break;
                default:
                    break;
            }
        }
    }

    const bookTransactionOnClickHandler = () => {
        let transaction = {
            batch:batch,
            article:article,
            inventoryCount:bookIn ? inventoryCount : -inventoryCount,
            toLocation:toLocation,
            assignedCustomer:assignedCustomer,
            assignedSupplier:assignedSupplier
        }
        if(validateTransaction(transaction)) {
            bookTransaction(transaction);
        }
    }

    const validateTransaction = (transaction) => {
        if(!transaction.article) {
            props.showMessage(2,"Jeder Transaktion muss ein Artikel zugewiesen werden");
            return false;
        }
        if(!transaction.inventoryCount) {
            props.showMessage(2,"Jeder Transaktion muss eine Anzahl zugewiesen werden");
            return false;
        }
        if(!transaction.article.id && !transaction.article.name) {
            props.showMessage(2,"Dem Artikel muss ein Name zugewiesen werden");
            return false;
        }
        if(!bookIn && transaction.assignedSupplier) {
            showInventoryInfoDialog("Sie haben einem Warenausgang einen Lieferanten zugewiesen. Wollen Sie dennoch fortfahren?");
            return false;
        }
        if(bookIn && transaction.assignedCustomer) {
            showInventoryInfoDialog("Sie haben einem Wareneingang einen Kunden zugewiesen. Wollen Sie dennoch fortfahren?");
            return false;
        }
        if(props.articleInformation && props.articleInformation.article && article.id && props.articleInformation.article.id === article.id && ((props.articleInformation.inventoryCount - props.articleInformation.reservationCount) >= 0 && (props.articleInformation.inventoryCount - props.articleInformation.reservationCount + transaction.inventoryCount) < 0)) {
            showInventoryInfoDialog("Sie sind dabei eine Buchung durchzuführen, die den freien Lagerbestand unterschreitet. Wollen Sie dennoch fortfahren?");
            return false;
        }
        return true;
    }

    const bookTransaction = (transaction) => {
        props.setLoading(true);
        api.post("/transactions",transaction)
            .then(response => {
                props.displayArticleInformation(null);
                props.addTransactionToHistory(response.data.transaction);
                if(!transaction.article.id) {
                    props.addArticleToList(response.data.transaction.article);
                }
                if(transaction.batch && !transaction.batch.id) {
                    props.addBatchToList(response.data.transaction.batch);
                }
                if(transaction.assignedCustomer && !transaction.assignedCustomer.id) {
                    props.addCustomerToList(response.data.transaction.assignedCustomer);
                    setAssignedCustomer(response.data.transaction.assignedCustomer)
                }
                if(transaction.assignedSupplier && !transaction.assignedSupplier.id) {
                    props.addSupplierToList(response.data.transaction.assignedSupplier);
                    setAssignedSupplier(response.data.transaction.assignedSupplier);
                }
                props.showMessage(0,"Transaktion erfolgreich verbucht!");
                setArticleBatches(null);
                clearFields();
                articleRef.current.focus();
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    };

    const deleteTransaction = (transaction) => {
        api.delete("/transactions/" + transaction.id)
            .then(response => {
                setActiveTransaction(null)
                props.removeTransactionFromHistory(transaction);
                props.showMessage(0,"Transaktion erfolgreich gelöscht!");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const loadBatchesByArticle = (article) => {
        api.get("/batches/search/article/" + article.id)
            .then(response => {
                console.log(response.data);
                setArticleBatches(response.data);
                props.displayBatchInformation(response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    const clearFields = () => {
        setBatch(null);
        setArticle(null);
        setInventoryCount("");
        props.getArticleInformation(null);
        props.displayBatchInformation(null);
    }

    const batchOnChange = (batch) => {
        let updatedBatch = batch ? { ...batch } : null;
        if(updatedBatch && updatedBatch.id) {
            inventoryCountRef.current.focus();
            props.setBlinkBatch(updatedBatch);
        }
        setBatch(updatedBatch);
    }

    const batchNrOnChangeHandler = (e) => {
        let updatedBatch = { ...batch };
        let normalizedValue = e.target.value;
        if(normalizedValue.startsWith("-")) {
            normalizedValue = normalizedValue.slice(1);
        }
        updatedBatch.id = null;
        updatedBatch.nr = normalizedValue;
        setBatch(updatedBatch);
    }

    const articleOnChange = (article) => {
        let updatedArticle = { ...article };
        if(updatedArticle) {
            if(!updatedArticle.articleGroup && props.company && props.company.defaultArticleGroup) {
                updatedArticle.articleGroup = props.company.defaultArticleGroup;
            }
        }
        if(updatedArticle && updatedArticle.id) {
            loadBatchesByArticle(updatedArticle);
            batchRef.current.focus();
        }
        if(!updatedArticle) {
            setBatch(null);
            setArticleBatches(null);
        }
        setArticle(updatedArticle);
        props.getArticleInformation(updatedArticle);
    }

    const articleNrOnChangeHandler = (e) => {
        let updatedArticle = { ...article };
        updatedArticle.id = null;
        let normalizedValue = e.target.value;
        if(normalizedValue.startsWith("-")) {
            normalizedValue = normalizedValue.slice(1);
        }
        updatedArticle.nr = normalizedValue;
        setArticle(updatedArticle);
        props.getArticleInformation(updatedArticle);
    }

    const articleNameOnChangeHandler = (e) => {
        const updatedArticle = {
            ...article,
            name:e.target.value
        }
        setArticle(updatedArticle);
    }

    const customerOnChangeHandler = (customer) => {
        setAssignedCustomer(customer);
        if(customer && customer.id) {
            articleRef.current.focus();
        }
    }

    const customerNameOnChangeHandler = (e) => {
        let updatedCustomer = { ...assignedCustomer };
        updatedCustomer.name = e.target.value;
        if(updatedCustomer) {
            setAssignedSupplier(null);
            setToLocation(null);
            if(e.target.value && e.target.value.includes(";")) {
                let customerAsString = e.target.value.split(";");
                if(customerAsString.length === 3) {
                    updatedCustomer = {
                        nr:customerAsString[0],
                        name:customerAsString[1] + " " + customerAsString[2]
                    };
                }
                for(let i = 0; i < props.customers.length; i++) {
                    if(props.customers[i].nr === updatedCustomer.nr) {
                        updatedCustomer = props.customers[i];
                    }
                }
                if(updatedCustomer && updatedCustomer.id) {
                    articleRef.current.focus();
                }
            }else if(e.target.value && /^[0-9]/.test(e.target.value) && e.target.value.includes("ö")) {
                let customerAsString = e.target.value.split("ö");
                if(customerAsString.length === 3) {
                    updatedCustomer = {
                        nr:customerAsString[0],
                        name:customerAsString[1] + " " + customerAsString[2]
                    };
                }
                for(let i = 0; i < props.customers.length; i++) {
                    if(props.customers[i].nr === updatedCustomer.nr) {
                        updatedCustomer = props.customers[i];
                    }
                }
                if(updatedCustomer && updatedCustomer.id) {
                    articleRef.current.focus();
                }
            }
        }
        setAssignedCustomer(updatedCustomer);
    }

    const debouncedCustomerNameHandler = debounce(customerNameOnChangeHandler, 300);

    const customerNrOnChangeHandler = (e) => {
        const updatedAssignedCustomer = {
            ...assignedCustomer,
            nr:e.target.value
        }
        setAssignedCustomer(updatedAssignedCustomer);
    };

    const supplierOnChangeHandler = (value) => {
        if(value) {
            setAssignedCustomer(null);
            setToLocation(null);
        }
        setAssignedSupplier(value);
    };

    const supplierNrOnChangeHandler = (e) => {
        const updatedAssignedSupplier = {
            ...assignedSupplier,
            nr:e.target.value
        }
        setAssignedSupplier(updatedAssignedSupplier);
    };

    const expirationDateOnChangeHandler = (e) => {
        const updatedBatch = {
            ...batch,
            expirationDate:e.target.value
        }
        setBatch(updatedBatch);
    };

    const simulateBarcodeScan = (inputString) => {
        let index = 0;

        const typeNextChar = () => {
            if (index < inputString.length) {
                // Simulate an event object with target.value
                const simulatedEvent = { target: { value: inputString.slice(0, index + 1) } };

                udiOnChangeHandler(simulatedEvent); // Call the handler with simulated event

                index++;
                setTimeout(typeNextChar, 5); // Schedule next character
            }
        };

        typeNextChar();
    };

    const normalizeCustomers = () => {
        props.setLoading(true);
        api.put("/customers/normalize")
            .then(response => {
                props.showMessage(0,"Hat geklappt")
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const udiOnChangeHandler = (e) => {
        let normalizedValue = e.target.value;
        if(normalizedValue && normalizedValue.startsWith("-")) {
            normalizedValue = normalizedValue.slice(1);
        }
        let updatedBatch = {
            ...batch,
            udi:normalizedValue
        }
        setBatch(updatedBatch);

        if(updatedBatch) {
            debouncedGetInformationByUDI(normalizedValue,updatedBatch)
        }
    };

    const getInformationByUDI = (normalizedValue,updatedBatch) => {
        let information = HelperFunctions.getInformationByUDI(normalizedValue,props.articles,props.batches);
        if(information) {
            setArticle(information.article);
            updatedBatch = information.batch;
            setBatch(updatedBatch);
            inventoryCountRef.current.focus();
            if(information.article) {
                props.getArticleInformation(information.article);
                loadBatchesByArticle(information.article);
            }
        }
    }

    const debouncedGetInformationByUDI = debounce(getInformationByUDI, 300);

    const inventoryCountOnChangeHandler = (count) => {
        if(count >= 0 || count === null) {
            setInventoryCount(count);
        }
    };

    const toLocationOnChangeHandler = (value) => {
        if(value) {
            setAssignedCustomer(null);
            setAssignedSupplier(null);
        }
        setToLocation(value);
    };

    const toggleBook = () => {
        if(!bookIn) {
            setToLocation(null);
        }
        setBookIn(!bookIn);
    };

    let bookText;
    if(bookIn) {
        bookText = <p className='SwitchText' style={{color:"#4D79FF"}}><b>Zugang</b></p>
    }else{
        bookText = <p className='SwitchText' style={{color:"#FF4D4D"}}><b>Abgang</b></p>
    }

    const generateLowMinimumStockList = () => {
        props.setLoading(true);
        api.get("/inventories/low", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'niedrige_bestaende.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const generatePriceSumListPDF = () => {
        props.setLoading(true);
        api.get("/inventories/prices/pdf", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'lagerbestand_gesamtwerte.pdf'; // Set the desired PDF filename
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const generatePriceSumListExcel = () => {
        props.setLoading(true);
        api.get("/inventories/prices/excel", { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'lagerbestand_gesamtwerte.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const updateTransactionInList = (transaction) => {
        console.log("[updateTransactionInList]:")
        console.log(transaction)
        let updatedTransactions = transactionsActive;
        for(let i = 0; i < updatedTransactions.length; i++) {
            if(updatedTransactions[i].id === transaction.id) {
                updatedTransactions[i] = transaction;
                break;
            }
        }
        setTransactionsActive(updatedTransactions);
        if(filteredTransactions) {
            let updatedFilteredTransactions = filteredTransactions;
            for(let i = 0; i < updatedFilteredTransactions.length; i++) {
                if(updatedFilteredTransactions[i].id === transaction.id) {
                    updatedFilteredTransactions[i] = transaction;
                    break;
                }
            }
            setFilteredTransactions(updatedFilteredTransactions);
        }
        props.updateTransactionInList(transaction);
    }

    const showInventoryInfoDialog = (infoText) => {
        setInfoText(infoText);
        setInventoryInfoDialog(true);
    }

    const closeInventoryInfoDialog = () => {
        setInfoText(null);
        setInventoryInfoDialog(false);
    }

    const showTransactionDialog = (transaction) => {
        setActiveTransaction(transaction);
        setTransactionDialog(true);
    }

    const closeTransactionDialog = () => {
        setTransactionDialog(false);
        setActiveTransaction(null);
    }

    const showDeleteTransactionDialog = (e,transaction) => {
        e.stopPropagation();
        setActiveTransaction(transaction);
        setDeleteTransactionDialog(true);
    }

    const closeDeleteTransactionDialog = () => {
        setDeleteTransactionDialog(false);
        setActiveTransaction(null);
    }

    const showSearchTransactionDialog = () => {
        setSearchTransactionDialog(true);
    }

    const closeSearchTransactionDialog = () => {
        setSearchTransactionDialog(false);
    }

    const showUpdateMinimumStockDialog = () => {
        setUpdateMinimumStockDialog(true);
    }

    const closeUpdateMinimumStockDialog = () => {
        setUpdateMinimumStockDialog(false);
    }

    return (
        <div className='article-movement'>
            <Tooltip id="article-movement-tooltip"/>

            <YesCancelDialog
                open={inventoryInfoDialog}
                close={closeInventoryInfoDialog}
                header='Warnung'
                text={infoText}
                onClick={() => bookTransaction({
                    batch: batch,
                    article: article,
                    inventoryCount: bookIn ? inventoryCount : -inventoryCount,
                    toLocation: toLocation,
                    assignedCustomer: assignedCustomer,
                    assignedSupplier: assignedSupplier
                })}
            />

            <ImportDataDialog
                open={updateMinimumStockDialog}
                close={closeUpdateMinimumStockDialog}
                processMultipleItems={props.updateMinimumStock}
            />

            {activeTransaction ? <TransactionDialog
                open={transactionDialog}
                close={closeTransactionDialog}
                transaction={activeTransaction}
                showMessage={props.showMessage}
                setLoading={props.setLoading}
                updateTransactionInList={updateTransactionInList}
                displayArticleInformation={props.displayArticleInformation}
                displayBatchInformation={props.displayBatchInformation}
            /> : null}

            <SecureYesCancelDialog
                open={deleteTransactionDialog}
                close={closeDeleteTransactionDialog}
                header='Löschen'
                text='Wollen Sie die Transaktion wirklich löschen?'
                onClick={() => deleteTransaction(activeTransaction)}
                showMessage={props.showMessage}
            />

            <SearchTransactionDialog
                open={searchTransactionDialog}
                close={closeSearchTransactionDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                setLoading={props.setLoading}
                articles={props.articles}
                customers={props.customers}
                suppliers={props.suppliers}
                getArticleInformation={props.getArticleInformation}
                showTransactionDialog={showTransactionDialog}
                showDeleteTransactionDialog={showDeleteTransactionDialog}
                removeTransactionFromHistory={props.removeTransactionFromHistory}
                updateTransactionInList={updateTransactionInList}
                displayArticleInformation={props.displayArticleInformation}
                displayBatchInformation={props.displayBatchInformation}

                // Batches
                batches={props.batches}
                addBatchToList={props.addBatchToList}
                updateBatchInList={props.updateBatchInList}
                removeBatchFromList={props.removeBatchFromList}
            />

            <div className="transactions-holder">
                <div className="actions-holder">
                    <SearchIcon onClick={showSearchTransactionDialog} className="icon search-icon"
                                data-tooltip-id="article-movement-tooltip"
                                data-tooltip-place="top" data-tooltip-content="Transaktionssuche"/>

                    <WarningAmberIcon className="icon" onClick={generateLowMinimumStockList}
                                      data-tooltip-id="article-movement-tooltip" data-tooltip-place="top"
                                      data-tooltip-content="Unterschrittene Mindestbestände exportieren (Excel)"/>

                    {props.user && props.user.role === "ADMIN" ?
                        <TableRowsIcon className="icon" onClick={generatePriceSumListExcel}
                                       data-tooltip-id="article-movement-tooltip" data-tooltip-place="top"
                                       data-tooltip-content="Gesamtwarenwert exportieren (Excel)"/> : null}

                    {props.user && props.user.role === "ADMIN" ?
                        <PictureAsPdfIcon className="icon" onClick={generatePriceSumListPDF}
                                          data-tooltip-id="article-movement-tooltip" data-tooltip-place="top"
                                          data-tooltip-content="Gesamtwarenwert exportieren (PDF)"/> : null}

                    {false && props.user && props.user.role === "ADMIN" ?
                        <ShowChartIcon className="icon" onClick={showUpdateMinimumStockDialog}
                                       data-tooltip-id="article-movement-tooltip" data-tooltip-place="top"
                                       data-tooltip-content="Mindestbestände aktualisieren (csv)"/> : null}

                    <ToolTextField value={searchWord}
                                   onChange={searchWordOnChangeHandler}
                                   className='search-input' label='Transaktionen der letzten 7 Tage durchsuchen'
                                   placeholder="Suchwort" size='small'/>
                    {props.user && props.user.role && props.user.role === "ADMIN" ? <Switch
                        checked={transactionsActive}
                        onChange={() => updateTransactionsActive(!transactionsActive)}
                        className='locked-transactions-fcl'
                        data-tooltip-id="article-movement-tooltip"
                        data-tooltip-place="top"
                        data-tooltip-content={transactionsActive ? "Transaktionen deaktivieren" : "Transaktionen aktivieren"}
                    /> : null}
                </div>
                <div className="transaction-history">
                    {props.transactions && !searchWord ? props.transactions.map((transaction, index) => (
                        <TransactionItem
                            key={transaction.id}
                            index={index}
                            transaction={transaction}
                            showTransactionDialog={showTransactionDialog}
                            showDeleteTransactionDialog={showDeleteTransactionDialog}
                        />
                    )) : props.transactions && searchWord && filteredTransactions != null ? filteredTransactions.map((transaction, index) => (
                        <TransactionItem
                            key={transaction.id}
                            index={index}
                            transaction={transaction}
                            showTransactionDialog={showTransactionDialog}
                            showDeleteTransactionDialog={showDeleteTransactionDialog}
                        />
                    )) : <div className="circular-progress-div">
                        <CircularProgress/>
                    </div>}
                </div>
            </div>

            <div className="article-movement-content">
                <FormControlLabel
                    className='book-switch'
                    control={
                        <Switch
                            checked={bookIn}
                            onChange={toggleBook}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={bookText}/>
                <div className='inventory-actions'>
                    <div className="category-holder">
                        <div>
                            <Autocomplete
                                disabled={assignedSupplier || toLocation}
                                size="small"
                                value={assignedCustomer ? assignedCustomer.name : ''}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        customerOnChangeHandler({
                                            name: newValue,
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        customerOnChangeHandler({
                                            name: newValue.inputValue,
                                        });
                                    } else {
                                        customerOnChangeHandler(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const {inputValue} = params;
                                    if (inputValue.length === 0) {
                                        return [];
                                    }
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            name: `Erstellen "${inputValue}"`,
                                        });
                                    }

                                    return filtered.slice(0, 20);
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="customers"
                                className='field'
                                options={props.customers}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.nr + option.name;
                                }}

                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                freeSolo
                                renderInput={(params) => (
                                    <ToolTextField size="small" {...params}
                                                   inputProps={{...params.inputProps, maxLength: 45}}
                                                   InputLabelProps={{shrink: true}}
                                                   inputRef={customerRef}
                                                   onChange={debouncedCustomerNameHandler}
                                                   id="customer"
                                                   label="Kundenname"/>
                                )}
                            />
                            <ToolTextField size="small" className='field'
                                           label="Kundennr"
                                           disabled={!assignedCustomer || assignedCustomer.id}
                                           value={assignedCustomer && assignedCustomer.nr ? assignedCustomer.nr : ''}
                                           inputProps={{maxLength: 45}}
                                           data-tooltip-id="article-movement-tooltip"
                                           data-tooltip-place="top"
                                           data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                           type="number"
                                           InputLabelProps={{shrink: true}}
                                           onChange={customerNrOnChangeHandler}/>
                        </div>
                    </div>
                    {props.company && props.company.abbreviation && props.company.abbreviation !== "BERGE" && props.company.abbreviation !== "LEENG" ?
                        <div className="category-holder">
                            <div>
                                <Autocomplete
                                    disabled={assignedCustomer || toLocation !== null}
                                    size="small"
                                    value={assignedSupplier ? assignedSupplier.name : ''}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            supplierOnChangeHandler({
                                                name: newValue,
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            supplierOnChangeHandler({
                                                name: newValue.inputValue,
                                            });
                                        } else {
                                            supplierOnChangeHandler(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const {inputValue} = params;
                                        if (inputValue.length === 0) {
                                            return [];
                                        }
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                name: `Erstellen "${inputValue}"`,
                                            });
                                        }

                                        return filtered.slice(0, 20);
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="suppliers"
                                    className='field'
                                    options={props.suppliers}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <ToolTextField id="supplier" size="small" {...params}
                                                       inputProps={{...params.inputProps, maxLength: 45}}
                                                       InputLabelProps={{shrink: true}}
                                                       onKeyDown={_handleKeyDown}
                                                       label="Lieferantenname"/>
                                    )}
                                />
                                {assignedSupplier && !assignedSupplier.id ? <ToolTextField size="small" className='field'
                                                                                           label="Lieferantennummer"
                                                                                           value={assignedSupplier && assignedSupplier.nr ? assignedSupplier.nr : ''}
                                                                                           inputProps={{maxLength: 45}}
                                                                                           data-tooltip-id="article-movement-tooltip"
                                                                                           data-tooltip-place="top"
                                                                                           data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                                                                           type="number"
                                                                                           InputLabelProps={{shrink: true}}
                                                                                           onChange={supplierNrOnChangeHandler}/> : null}
                            </div>
                        </div> : null}
                    {props.company && props.company.abbreviation && props.company.abbreviation !== "BERGE" && props.company.abbreviation !== "LEENG" ?
                        <div className="category-holder">
                            <Autocomplete
                                disabled={bookIn || assignedSupplier || assignedCustomer}
                                size="small"
                                value={toLocation ? toLocation.name : ''}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        toLocationOnChangeHandler({
                                            name: newValue,
                                        });
                                    } else {
                                        toLocationOnChangeHandler(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const {inputValue} = params;
                                    if (inputValue.length === 0) {
                                        return [];
                                    }
                                    // Suggest the creation of a new value

                                    return filtered.slice(0, 20);
                                }}
                                selectOnFocus
                                clearOnBlur
                                id="customers"
                                className='field'
                                options={props.locations}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                }}

                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                freeSolo
                                renderInput={(params) => (
                                    <ToolTextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 45}}
                                                   InputLabelProps={{shrink: true}}
                                                   label="nach Standort"/>
                                )}
                            />
                        </div> : null}
                    <div className="category-holder">
                        <Autocomplete
                            size="small"
                            value={article && article.nr ? article.nr : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    articleOnChange({
                                        nr: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    articleOnChange({
                                        nr: newValue.inputValue
                                    }, true);
                                } else {
                                    articleOnChange(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const {inputValue} = params;
                                if (inputValue.length === 0) {
                                    return [];
                                }
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.name);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        nr: `${inputValue}`,
                                        name: "Erstellen: "
                                    });
                                }
                                return filtered.slice(0, 50);
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            className='field'
                            options={props.articles}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.id + option.nr + option.name + option.pcn + option.ean + option.refNr;
                            }}
                            renderOption={(props, option) => <li
                                key={option.name} {...props}>{option.name + " (" + option.nr + ")"}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <ToolTextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                               InputLabelProps={{shrink: true}}
                                               inputRef={articleRef}
                                               onChange={articleNrOnChangeHandler}
                                               label="Artikelnummer" value={article && article.name ? article.name : ''}/>
                            )}
                        />
                        <ToolTextField size="small" className='field' disabled={!(article && article.nr && !article.id)}
                                       label="Artikelname"
                                       value={article && article.name ? article.name : ''}
                                       inputProps={{maxLength: 255}}
                                       InputLabelProps={{shrink: true}}
                                       onChange={articleNameOnChangeHandler}/>
                    </div>
                    <div className="category-holder">
                        <Autocomplete
                            id="batchNr"
                            size="small"
                            value={batch && batch.nr ? batch.nr : ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    batchOnChange({
                                        nr: newValue,
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    batchOnChange({
                                        nr: newValue.inputValue
                                    }, true);
                                } else {
                                    batchOnChange(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const {inputValue} = params;
                                // Suggest the creation of a new value
                                if (inputValue !== '') {
                                    filtered.push({
                                        inputValue,
                                        nr: `Erstellen "${inputValue}"`,
                                    });
                                }
                                return filtered.slice(0, 20);
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            className='field'
                            options={articleBatches !== null && articleBatches.length > 0 && (!batch || !batch.nr) ? articleBatches : props.batches}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.nr;
                            }}
                            renderOption={(props, option) => <li
                                key={option.id} {...props}>{option.nr} {option.expirationDate ? "(" + HelperFunctions.formatDate(option.expirationDate) + ")" : null}</li>}
                            freeSolo
                            openOnFocus
                            renderInput={(params) => (
                                <ToolTextField size="small" {...params} inputProps={{...params.inputProps, maxLength: 255}}
                                               InputLabelProps={{shrink: true}}
                                               inputRef={batchRef}
                                               onChange={batchNrOnChangeHandler}
                                               label="Chargennummer" value={batch && batch.nr ? batch.nr : ''}/>
                            )}
                        />
                        <ToolTextField id="udi" inputProps={{maxLength: 255}} disabled={batch && batch.id} className='field'
                                       size="small" onChange={udiOnChangeHandler} label="UDI"
                                       InputLabelProps={{shrink: true}}
                                       onKeyDown={_handleKeyDown}
                                       value={batch && batch.udi ? batch.udi : ""}/>
                    </div>
                    <div className="category-holder">
                        <ToolTextField inputProps={{maxLength: 45}} disabled={batch && batch.id} className='field'
                                       type='date'
                                       size="small" onChange={expirationDateOnChangeHandler} label="Mindesthaltbarkeitsdatum"
                                       InputLabelProps={{shrink: true}}
                                       value={batch && batch.expirationDate ? batch.expirationDate : ''}/>
                    </div>
                    <div className="category-holder">
                        <ToolTextField id="inventoryCount" size="small" label='Anzahl' onChange={(e) => inventoryCountOnChangeHandler(e.target.value)}
                                       onKeyDown={_handleKeyDown}
                                       InputLabelProps={{shrink: true}}
                                       inputRef={inventoryCountRef}
                                       value={inventoryCount}
                                       className='field small-field' type="number"/>
                    </div>
                </div>

                <ToolButton disabled={!transactionsActive} main onClick={bookTransactionOnClickHandler}
                            className={['book-button',bookIn ? 'main-button' : 'negative-button'].filter(Boolean).join(' ')}>Verbuchen</ToolButton>
            </div>
            {bookIn ? <span className="article-movement-book-in-glow"/> :
                <span className="article-movement-book-out-glow"/>}
        </div>
    )
}

export default ArticleMovement;