import React, { useState, useEffect, useRef } from 'react';
import './StockTakingInventory.css';

//Components
import { Tooltip } from 'react-tooltip'
import ToolPaper from "../../../../../../../../reusable/ToolPaper/ToolPaper";

const StockTakingInventory = (props) => {
    return (
        <ToolPaper elevation={3} className='stock-taking-inventory' onClick={() => props.showStockTakingInventoryDialog(props.stockTakingInventory)}>
            <Tooltip id="stock-taking-inventory-tooltip"/>
            <ul className="content-ul">
                <li><p>{props.stockTakingInventory && props.stockTakingInventory.article && props.stockTakingInventory.article.nr ? props.stockTakingInventory.article.nr : "-"}</p></li>
                <li><p>{props.stockTakingInventory && props.stockTakingInventory.article.name ? props.stockTakingInventory.article.name : "-"}</p></li>
                <li><p>{props.stockTakingInventory.inventoryCount}</p></li>
            </ul>
        </ToolPaper>
    )
}

export default StockTakingInventory;