import React, { useState } from "react";
import "./DataChart.css";

//Components
import {CartesianGrid, Line, LineChart, Tooltip, XAxis} from "recharts";

const DataChart = (props) => {

    const [screenWidth, setScreenWidth] = useState(document.getElementById("app").offsetWidth);

    const mergeData = (transactions, customerOrders, supplierOrders) => {
        const mergedData = {};

        // Helper function to add data
        const addData = (source, key) => {
            source.forEach(entry => {
                const date = entry.Datum;
                if (!mergedData[date]) {
                    mergedData[date] = { Datum: date, "Transaktionen": 0, "Kundenbestellungen": 0, "Lieferantenbestellungen": 0 };
                }
                mergedData[date][key] = entry[key] || 0;
            });
        };

        // Merge all data sources
        addData(transactions, "Transaktionen");
        addData(customerOrders, "Kundenbestellungen");
        addData(supplierOrders, "Lieferantenbestellungen");

        // Convert object back to array
        return Object.values(mergedData).sort((a, b) => new Date(a.Datum) - new Date(b.Datum));
    };

    const formattedData = mergeData(props.formattedTransactions, props.formattedCustomerOrders, props.formattedSupplierOrders);

    return (
        <div>
            {screenWidth ? <LineChart
                width={screenWidth > 800 ? 640 : 480}
                height={screenWidth > 800 ? 256 : 192}
                data={formattedData}
                className="data-chart"
            >
                <XAxis dataKey="Datum" />
                <Tooltip />
                <CartesianGrid stroke="#f5f5f5" />
                <Line type="monotone" dataKey="Transaktionen" stroke="#FF4D4D" yAxisId="0" />
                <Line type="monotone" dataKey="Kundenbestellungen" stroke="#2B4591" yAxisId="1" />
                <Line type="monotone" dataKey="Lieferantenbestellungen" stroke="#4D79FF" yAxisId="2" />
            </LineChart> : null}
        </div>
    )
}

export default DataChart;