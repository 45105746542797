import React, { useState, useEffect, useRef } from 'react';
import './TransactionItem.css';
import Paper from "@mui/material/Paper";
import HelperFunctions from "../HelperFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import ArticleIcon from "@mui/icons-material/Article";
import ToolPaper from "../ToolPaper/ToolPaper";

//Component

const TransactionItem = (props) => {

    let articleInfo = "";
    if(props.transaction && props.transaction.article && props.transaction.article.name) {
        articleInfo = props.transaction.article.name;
    }
    if(props.transaction && props.transaction.article && props.transaction.article.nr) {
        articleInfo = articleInfo + " (" + props.transaction.article.nr + ")";
    }

    return (
        <ToolPaper elevation={3}
                   className={props.transaction.inventoryCount >= 0 ? "transaction-item white-background active-glow-object animate" : "transaction-item light-background deactivated-glow-object animate"}
                   onClick={() => props.showTransactionDialog(props.transaction)}>
            <span/>
            <p className="date-time-info">{HelperFunctions.formatDateTime(props.transaction.dateTime)}</p>
            <p className="count-info"
               style={props.transaction.inventoryCount >= 0 ? {color: "#4D79FF"} : {color: "#F03C3C"}}>{props.transaction.inventoryCount}</p>
            <p className="article-info">{articleInfo}</p>
            {props.transaction.assignedCustomer ?
                <p className="business-partner-info">an {props.transaction.assignedCustomer.name}</p> : null}
            {props.transaction.assignedSupplier ?
                <p className="business-partner-info">von {props.transaction.assignedSupplier.name}</p> : null}
            {!props.small ? <DeleteIcon
                onClick={(e) => props.showDeleteTransactionDialog(e,props.transaction)}
                className='icon delete-icon'
            /> : null}

        </ToolPaper>
    )
}

export default TransactionItem;