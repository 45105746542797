import React, { useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import './SideMenu.css';

//Components
import wm_logo from '../../../../../images/wm_logo.png';
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import EmailIcon from "@mui/icons-material/Email";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StorageIcon from "@mui/icons-material/Storage";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SettingsIcon from "@mui/icons-material/Settings";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Tooltip} from "react-tooltip";

const SideMenu = (props) => {

    const [extended, setExtended] = useState(false);

    const history = useHistory();

    return (
        <div style={extended ? {width:"320px"} : {width:"96px"}} className='side-menu'>
            <Tooltip id="side-menu-tooltip"/>
            <div className="surrounder">
                <img className="module-icon" src={wm_logo} alt="Warehouse Manager 4.0 Logo"/>
                <ToolButton style={extended ? {opacity:"1"} : {opacity:"0"}} onClick={() => history.push("/")} className="module-button"
                            main>Modulübersicht</ToolButton>
                <ul className='navigation-items'>
                    <li>
                        <NavLink className='nav-item' activeClassName='active' exact to='/warehouse-manager'>
                            <DashboardIcon className='icon'
                                           data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                           data-tooltip-content={extended ? "" : "Übersicht"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Übersicht</h2>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-item' activeClassName='active'
                                 to='/warehouse-manager/order-management'>
                            <AssignmentIcon className='icon'
                                            data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                            data-tooltip-content={extended ? "" : "Bestellwesen"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Bestellwesen</h2>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-item' activeClassName='active'
                                 to='/warehouse-manager/inventory'>
                            <WarehouseIcon className='icon'
                                           data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                           data-tooltip-content={extended ? "" : "Inventar"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Inventar</h2>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-item' activeClassName='active'
                                 to='/warehouse-manager/communication'>
                            <EmailIcon className='icon'
                                       data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                       data-tooltip-content={extended ? "" : "Kommunikation"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Kommunikation</h2>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-item' activeClassName='active'
                                 to='/warehouse-manager/reporting'>
                            <AssessmentIcon className='icon'
                                            data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                            data-tooltip-content={extended ? "" : "Reporting"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Reporting</h2>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink className='nav-item' activeClassName='active'
                                 to='/warehouse-manager/master-data'>
                            <StorageIcon className='icon'
                                         data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                         data-tooltip-content={extended ? "" : "Stammdaten"}/>
                            <h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Stammdaten</h2>
                        </NavLink>
                    </li>
                    <li>
                        {props.user && props.user.role && props.user.role === "ADMIN" ?
                            <NavLink className='nav-item' activeClassName='active'
                                     to='/warehouse-manager/administration'>
                                <SupervisorAccountIcon className='icon'
                                                       data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                                       data-tooltip-content={extended ? "" : "Administration"}/>
                                <h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Administration</h2>
                            </NavLink> : null}
                    </li>
                </ul>
                <NavLink className='nav-item settings-nav-item' activeClassName='active'
                         to='/warehouse-manager/settings'>
                    <SettingsIcon className='icon settings-icon'
                                           data-tooltip-id="article-information-tooltip" data-tooltip-place="right"
                                           data-tooltip-content={extended ? "" : "Einstellungen"}/><h2 style={extended ? {opacity:"1"} : {opacity:"0"}}>Einstellungen</h2>
                </NavLink>
                <a className="company-website" href="https://www.panthera-software.com" target="_blank"
                   rel="noopener noreferrer" style={extended ? {opacity:"1"} : {opacity:"0"}}>2025 Panthera Software Solutions GmbH</a>
            </div>
            {extended ? <ArrowBackIosIcon onClick={() => setExtended(false)} className="icon extend-icon"/> :
                <ArrowForwardIosIcon onClick={() => setExtended(true)} className="icon extend-icon"/>}
        </div>
    )
}


export default SideMenu;