import React, { useState, useEffect, useRef } from 'react';
import './AutomatedCommunicationSettingDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../../api";
import Paper from "@mui/material/Paper";
import ToolFab from "../../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToolTextField from "../../../../../../../../reusable/ToolField/ToolTextField";

const AutomatedCommunicationSettingDialog = (props) => {

    const [automatedCommunicationSetting,setAutomatedCommunicationSetting] = useState(null);

    useEffect(() => {
        setAutomatedCommunicationSetting(props.automatedCommunicationSetting);
    }, []);

    const kindOnChangeHandler = (e) => {
        let updatedAutomatedCommunicationSetting = { ...automatedCommunicationSetting };
        updatedAutomatedCommunicationSetting.kind = e.target.value;
        setAutomatedCommunicationSetting(updatedAutomatedCommunicationSetting);
    }

    const daysOnChangeHandler = (e) => {
        let updatedAutomatedCommunicationSetting = { ...automatedCommunicationSetting };
        updatedAutomatedCommunicationSetting.days = e.target.value;
        setAutomatedCommunicationSetting(updatedAutomatedCommunicationSetting);
    }

    const subjectOnChangeHandler = (e) => {
        let updatedAutomatedCommunicationSetting = { ...automatedCommunicationSetting };
        updatedAutomatedCommunicationSetting.subject = e.target.value;
        setAutomatedCommunicationSetting(updatedAutomatedCommunicationSetting);
    }

    const messageOnChangeHandler = (e) => {
        let updatedAutomatedCommunicationSetting = { ...automatedCommunicationSetting };
        updatedAutomatedCommunicationSetting.message = e.target.value;
        setAutomatedCommunicationSetting(updatedAutomatedCommunicationSetting);
    }

    const toggleActive = () => {
        let updatedAutomatedCommunicationSetting = { ...automatedCommunicationSetting };
        updatedAutomatedCommunicationSetting.active = !updatedAutomatedCommunicationSetting.active;
        setAutomatedCommunicationSetting(updatedAutomatedCommunicationSetting);
    }

    const saveOnClickHandler = (automatedCommunicationSetting) => {
        if(validateAutomatedCommunicationSetting(automatedCommunicationSetting)) {
            if(automatedCommunicationSetting.id) {
                updateAutomatedCommunicationSetting(automatedCommunicationSetting);
            }else{
                createAutomatedCommunicationSetting(automatedCommunicationSetting);
            }
        }
    }

    const validateAutomatedCommunicationSetting = (automatedCommunicationSetting) => {
        if(!automatedCommunicationSetting.kind) {
            props.showMessage(2,"Es muss eine Aktionsart ausgewählt werden");
            return false;
        }
        if(automatedCommunicationSetting.days === null || automatedCommunicationSetting.days <= 0) {
            props.showMessage(2,"Die Tage müssen ausgefüllt und größer 0 sein");
            return false;
        }
        if(!automatedCommunicationSetting.subject) {
            props.showMessage(2,"Der Betreff darf nicht leer sein");
            return false;
        }
        if(!automatedCommunicationSetting.message) {
            props.showMessage(2,"Die Nachricht darf nicht leer sein");
            return false;
        }
        return true;
    }

    const createAutomatedCommunicationSetting = (automatedCommunicationSetting) => {
        props.setLoading(true);
        api.post("/automated-communications/settings",automatedCommunicationSetting)
            .then(response => {
                props.addAutomatedCommunicationSettingToList(response.data);
                props.setLoading(false);
                props.showMessage(0,"Die automatisierte Aktion wurde angelegt.");
                props.close();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const updateAutomatedCommunicationSetting = (automatedCommunicationSetting) => {
        props.setLoading(true);
        api.put("/automated-communications/settings",automatedCommunicationSetting)
            .then(response => {
                props.updateAutomatedCommunicationSettingInList(response.data);
                props.setLoading(false);
                props.showMessage(0,"Die Änderungen wurden gespeichert");
                props.close();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const deleteAutomatedCommunicationSetting = (automatedCommunicationSetting) => {
        props.setLoading(true);
        api.delete("/automated-communications/settings/" + automatedCommunicationSetting.id)
            .then(response => {
                props.removeAutomatedCommunicationSettingFromList(response.data);
                props.setLoading(false);
                props.showMessage(0,"Die automatisierte Aktion wurde gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    let activeLabel = "";
    if(automatedCommunicationSetting && automatedCommunicationSetting.active) {
        activeLabel = "Aktiv";
    }else{
        activeLabel = "Inaktiv";
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='automated-communication-setting-dialog'>
            <div className='surrounder'>
                <DialogTitle>Automatisierte Aktion</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        className='switch-label'
                        control={
                            <Switch className='switch position-display-switch' checked={automatedCommunicationSetting && automatedCommunicationSetting.active}
                                    onChange={toggleActive} color="primary"/>
                        }
                        label={activeLabel}
                    />
                    <ToolTextField label="Aktionsart" size="small" onChange={kindOnChangeHandler} value={automatedCommunicationSetting && automatedCommunicationSetting.kind ? automatedCommunicationSetting.kind : null} className="field" select InputLabelProps={{shrink: true}}>
                        <MenuItem value="CUSTOMER_OFFER_REMINDER">Kundenangebots-Erinnerung</MenuItem>
                        <MenuItem value="SUPPLIER_OFFER_REMINDER">Lieferantenangebots-Erinnerung</MenuItem>
                        <MenuItem value="SUPPLIER_ORDER_CONFIRMATION_REMINDER">Lieferantenauftragsbestätigungs-Erinnerung</MenuItem>
                    </ToolTextField>
                    <ToolTextField label="Tage" onChange={daysOnChangeHandler} size="small"
                               InputLabelProps={{shrink: true}} type="number" value={automatedCommunicationSetting && automatedCommunicationSetting.days ? automatedCommunicationSetting.days : null} className="field small-field"/>
                    <p className="small-info">
                        {automatedCommunicationSetting && automatedCommunicationSetting.kind === "CUSTOMER_OFFER_REMINDER" ? "Bei einer Kundenangebots-Erinnerung wird nach der auswählten Anzahl an Tagen eine automatisierte Kommunikation erstellt, in welcher der Kunde erinnert werden soll, auf ein erstelltes Angebot zu reagieren. Vorraussetzung dafür, ist eine Kundenbestellung mit dem Status 'Angebotserstellung', eine für den Kunden hinterlegte E-Mail Adresse sowie ein Dokument der Art 'Angebot' mit dem Dokumentendatum entsprechend der angegebenen Tage in der Vergangenheit." : null}
                        {automatedCommunicationSetting && automatedCommunicationSetting.kind === "SUPPLIER_OFFER_REMINDER" ? "Bei einer Lieferantenangebots-Erinnerung wird nach der auswählten Anzahl an Tagen eine automatisierte Kommunikation erstellt, in welcher der Lieferant erinnert werden soll, auf eine Anfrage zu reagieren. Vorraussetzung dafür, ist eine Lieferantenbestellung mit dem Status 'Angebot angefragt', eine für den Lieferanten hinterlegte E-Mail Adresse sowie ein Dokument der Art 'Bestellung' mit dem Dokumentendatum entsprechend der angegebenen Tage in der Vergangenheit." : null}
                        {automatedCommunicationSetting && automatedCommunicationSetting.kind === "SUPPLIER_ORDER_CONFIRMATION_REMINDER" ? "Bei einer Lieferantenauftragsbestätigungs-Erinnerung wird nach der auswählten Anzahl an Tagen eine automatisierte Kommunikation erstellt, in welcher der Lieferant erinnert werden soll, auf eine angefragte Bestellung zu reagieren. Vorraussetzung dafür, ist eine Lieferantenbestellung mit dem Status 'Bestellung angefragt', eine für den Lieferanten hinterlegte E-Mail Adresse sowie ein Dokument der Art 'Bestellung' mit dem Dokumentendatum entsprechend der angegebenen Tage in der Vergangenheit." : null}
                    </p>
                    <ToolTextField onChange={subjectOnChangeHandler}
                               InputLabelProps={{shrink: true}} size="small"
                               label='Betreff (maximal 255 Zeichen)' inputProps={{maxLength: 255}} value={automatedCommunicationSetting && automatedCommunicationSetting.subject ? automatedCommunicationSetting.subject : null} className="field"/>
                    <ToolTextField onChange={messageOnChangeHandler} multiline maxRows={15}
                               InputLabelProps={{shrink: true}} size="small"
                               label='Nachricht (maximal 1000 Zeichen)' inputProps={{maxLength: 1000}} value={automatedCommunicationSetting && automatedCommunicationSetting.message ? automatedCommunicationSetting.message : null} className="field textarea"/>
                    <p className="small-info">
                        In der Nachricht an den Geschäftspartner können variable Referenzen wie z.B. eine Dokumentennummer
                        aufgenommen werden. Dies kann durch die Bezeichnung in eckigen Klammern passieren (Beispiel:
                        [documentNr]). Diese wird dann in der Nachricht durch die richtige Dokumentennummer ersetzt.
                        Bezieht sich die Referenz auf einen Anhang, wird jeweils der Anhang an erster Stelle (zuerst angefügt) beachtet.

                        Mögliche Referenzen sind:<br/><br/>

                        Dokumentennummer: [documentNr]<br/>
                        Dokumentendatum: [documentDate]<br/>
                        Bestellnummer: [orderNr]<br/>
                        Bestelldatum: [orderDate]<br/>
                        Kommissionsnummr: [commissionNr]<br/>
                        Kundennummer: [customerNr]<br/>
                        Kundenname: [customerName]<br/>
                        Lieferantennummer: [supplierNr]<br/>
                        Lieferantenname: [supplierName]<br/><br/>

                        Ist der Wert nicht vorhanden, bleibt der Platzhalter bestehen und kann vorm Versenden bearbeitet werden.
                    </p>
                </DialogContent>
                <DialogActions>
                    <ToolButton main className='field'
                                onClick={() => saveOnClickHandler(automatedCommunicationSetting)}>Speichern</ToolButton>
                </DialogActions>
            </div>
        </Dialog>
    )
}

export default AutomatedCommunicationSettingDialog;