import React, {Component} from "react";
import './PdfMenuBar.css';

//Components
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import GestureIcon from '@mui/icons-material/Gesture';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import ToolTextField from "../../../../ToolField/ToolTextField";

class PdfMenuBar extends Component {

    render() {
        return (
            <div className='pdf-menu-bar'>
                <ul>
                    <li>
                        <PostAddIcon className='icon' onClick={this.props.showAttachPdfDialog}/>
                    </li>
                    <li>
                        <SaveIcon className='icon' onClick={this.props.updatePdf}/>
                    </li>
                    <li>
                        <DownloadIcon className='icon' onClick={this.props.downloadPdf}/>
                    </li>
                    <li>
                        <GestureIcon className='icon' onClick={this.props.toggleDrawingActive}/>
                        {this.props.drawingActive ?
                            <div className="drawing-settings">
                                <div onClick={() => this.props.colorOnChangeHandler("#FFFFFF")}
                                     style={{background: "#FFFFFF"}} className='color-picker-item'/>
                                <div onClick={() => this.props.colorOnChangeHandler("#000000")}
                                     style={{background: "#000000"}} className='color-picker-item'/>
                                <div onClick={() => this.props.colorOnChangeHandler("#808080")}
                                     style={{background: "#808080"}} className='color-picker-item'/>
                                <div onClick={() => this.props.colorOnChangeHandler("#FF0000")}
                                     style={{background: "#FF0000"}} className='color-picker-item'/>
                                <div onClick={() => this.props.colorOnChangeHandler("#0000FF")}
                                     style={{background: "#0000FF"}} className='color-picker-item'/>
                                <div onClick={() => this.props.colorOnChangeHandler("#00FF00")}
                                     style={{background: "#00FF00"}} className='color-picker-item'/>
                                <ToolTextField value={this.props.size} type='number'
                                           onChange={this.props.sizeOnChangeHandler} size='small'
                                           className='stroke-size-input' label='Stiftgröße'>12</ToolTextField>
                                <div className='stroke-preview' style={{
                                    background: `${this.props.color}`,
                                    width: `${this.props.size}px`,
                                    height: `${this.props.size}px`,
                                    borderRadius: `${this.props.size / 2}px`
                                }}/>
                            </div> : null}
                    </li>
                    <li>
                        <p className='page-p'>Seite {this.props.currentPage} von {this.props.pageCount}</p>
                    </li>
                    <div className='navigate-icon-holder prev' onClick={this.props.onPrevPage}><ArrowBackIosIcon
                        className='navigate-icon'/></div>
                    <div className='navigate-icon-holder next' onClick={this.props.onNextPage}><ArrowForwardIosIcon
                        className='navigate-icon'/></div>
                </ul>
            </div>
        )
    }
}

export default PdfMenuBar;