import React, {Component} from "react";
import './WarehouseDashboard.css';
import DataChart from "../../../../../../reusable/DataChart/DataChart";
import CircularProgress from "@mui/material/CircularProgress";

//Components

class WarehouseDashboard extends Component {
    render() {
        return (
            <div className='warehouse-dashboard'>
                <h1>Willkommen {this.props.user && this.props.user.username ? this.props.user.username : 'Lädt...'}!</h1>
                <div className="holder">
                    {this.props.formattedTransactions !== null && this.props.formattedCustomerOrders !== null && this.props.formattedSupplierOrders !== null ?
                        <DataChart
                            formattedTransactions={this.props.formattedTransactions}
                            formattedCustomerOrders={this.props.formattedCustomerOrders}
                            formattedSupplierOrders={this.props.formattedSupplierOrders}
                        /> : <div className="circular-progress-div">
                            <CircularProgress/>
                        </div>}
                </div>
            </div>
        )
    }
}

export default WarehouseDashboard;