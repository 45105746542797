import React, {Component, useState} from "react";
import "./ToolButton.css";

//Components

const ToolButton = (props) => {

    return (
        <div style={props.style} className={['tool-button animate',props.className,props.main ? 'main-button' : 'secondary-button'].filter(Boolean).join(' ')} onClick={props.onClick}>
            <p className="white">{props.children}</p>
        </div>
    )
}

export default ToolButton;