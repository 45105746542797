import React, {Component} from 'react';
import './HistoryPanel.css';

//Components
import HistoryIcon from '@mui/icons-material/History';
import api from "../../api";
import HistoryEntry from "./HistoryEntry/HistoryEntry";
import LoadingScreen from "../../components/Tool/ModuleOverview/CommonComponents/LoadingScreen/LoadingScreen";

class HistoryPanel extends Component {

    state = {
        historyEntries:[],
        unfolded:false,
        loading:false
    }

    loadHistoryEntries = () => {
        const url = '/history-entries';
        api.get(url)
            .then(response => {
                this.setState({historyEntries:response.data,loading:false});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    open = () => {
        this.loadHistoryEntries();
        this.setState({unfolded:true});
    }

    close = () => {
        this.setState({unfolded:false});
    }

    toggleUnfolded = () => {
        if(!this.state.unfolded) {
            this.setState({loading:true});
            this.open();
        }else{
            this.close();
        }
    }

    render() {

        let style;

        if(this.state.unfolded) {
            style = {
                width:"384px"
            }
        }else{
            style = {
                width:"0"
            }
        }

        let content;
        if(this.state.loading) {
            content = <LoadingScreen/>
        }else{
            content = <div>
                {this.state.historyEntries ? this.state.historyEntries.sort((a, b) => b.dateTime.localeCompare(a.dateTime)).map((historyEntry,index) => (
                    <HistoryEntry
                        key={historyEntry.id}
                        index={index}
                        historyEntry={historyEntry}
                    />
                )) : null}
            </div>
        }

        return (
            <div className='history-panel'>
                <ul>
                    <li className="button-li">
                        <div onClick={this.toggleUnfolded} className="clickable toggle-button box-shadow-dark primary-color-background-light">
                            <HistoryIcon className="history-icon"/>
                        </div>
                    </li>
                    <li className="content-li">
                        <div className="content-holder box-shadow-dark white-background" style={style}>
                            {content}
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default HistoryPanel;