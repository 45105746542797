import React, { useState, useEffect, useRef } from 'react';
import './Batches.css';

//Components
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import { Tooltip } from 'react-tooltip'
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import BatchDialog from "./BatchDialog/BatchDialog";
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import api from "../../../../../../../api";
import OrderItem from "../../../../CommonComponents/Actions/OrderManagement/Orders/OrderItem/OrderItem";
import BatchItem from "../../../../../../../reusable/BatchItem/BatchItem";
import SecureYesCancelDialog from "../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import ToolTextField from "../../../../../../../reusable/ToolField/ToolTextField";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import helperFunctions from "../../../../../../../reusable/HelperFunctions";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";

const Batches = (props) => {

    const [searchType,setSearchType] = useState("free-search");
    const [searchWord,setSearchWord] = useState(null);
    const [includeCompletedBatches,setIncludeCompletedBatches] = useState(false);
    const [sort,setSort] = useState(null);
    const [batch,setBatch] = useState(null);
    const [batches,setBatches] = useState(null);
    const [isNewitem,setIsNewItem] = useState(null);

    const [batchDialog,setBatchDialog] = useState(false);
    const [deleteBatchDialog,setDeleteBatchDialog] = useState(false);

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchOnClick();
        }
    }

    const searchOnClick = () => {
        if(searchWord) {
            props.setLoading(true);
            let url;
            if(includeCompletedBatches) {
                url = "/batches/search/free-search/all/" + searchWord;
            }else{
                url = "/batches/search/free-search/uncompleted/" + searchWord;
            }
            api.get(url)
                .then(response => {
                    console.log(response.data);
                    props.setLoading(false);
                    if(response.data.length === 0) {
                        props.showMessage(1,"Es wurden keine Chargen gefunden");
                    }
                    setBatches(response.data);
                })
                .catch(error => {
                    props.setLoading(false);
                    console.log(error);
                    if(error.response && error.response.data) {
                        props.showMessage(2,error.response.data);
                    } else if(!error.response && error.request) {
                        props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    } else {
                        props.showMessage(2,"Etwas ist schiefgelaufen");
                        props.logout();
                    }
                });
        }else{
            props.showMessage(2,"Es muss ein Suchwort eingegeben werden");
        }
    }

    const deleteBatch = (batch) => {
        props.setLoading(true);
        const url = "/batches/" + batch.id;
        api.delete(url)
            .then(response => {
                props.setLoading(false);
                removeBatchFromList(batch);
                props.showMessage(0,"Die Charge wurde erfolgreich gelöscht");
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    const loadUrgentBatches = () => {
        props.setLoading(true);
        let url = "/batches/search/urgent";
        api.get(url)
            .then(response => {
                props.setLoading(false);
                if(response.data.length === 0) {
                    props.showMessage(1,"Es wurden keine Chargen gefunden");
                }else{
                    setBatches(response.data);
                }
            })
            .catch(error => {
                props.setLoading(false);
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
            });
    }

    // Batches

    const addBatchToList = (batch) => {
        const updatedBatches = [ ...batches ];
        updatedBatches.push(batch);
        setBatches(updatedBatches);
        props.addBatchToList(batch);
    }

    const updateBatchInList = (batch) => {
        const updatedBatches = [ ...batches ];
        for(let i = 0; i < batches.length; i++) {
            if(updatedBatches[i].id === batch.id) {
                updatedBatches[i] = batch;
                props.updateBatchInList(batch);
                break;
            }
        }
        setBatches(updatedBatches);
    }

    const removeBatchFromList = (batch) => {
        const updatedBatches = [ ...batches ];
        for(let i = 0; i < updatedBatches.length; i++) {
            if (updatedBatches[i].id === batch.id) {
                updatedBatches.splice(i,1)
                setBatches(updatedBatches);
                props.removeBatchFromList(batch);
                return;
            }
        }
    }

    const sortedBatches = () => {
        return batches.sort((a, b) => {
            switch (sort) {
                case "nr":
                    if (a.nr === null || a.nr === undefined) {
                        return 1;
                    }
                    if (b.nr === null || b.nr === undefined) {
                        return -1;
                    }
                    return a.nr.localeCompare(b.nr);
                case "expirationDate":
                    return ((b.expirationDate ? new Date(b.expirationDate) : 0) - (a.expirationDate ? new Date(a.expirationDate) : 0));
                case "material":
                    return (b.material || 0) - (a.material || 0);
                case "inventoryCountFree":
                    if (!a.batchInventories || a.batchInventories.length === 0 || a.batchInventories[0] === null || a.batchInventories[0].inventoryCountFree === null) {
                        return 1;
                    }
                    if (!b.batchInventories || b.batchInventories.length === 0 || b.batchInventories[0] === null || b.batchInventories[0].inventoryCountFree === null) {
                        return -1;
                    }
                    return a.batchInventories[0].inventoryCountFree - b.batchInventories[0].inventoryCountFree;
                case "articleNr":
                    if (!a.batchInventories || a.batchInventories.length === 0 || a.batchInventories[0] === null || a.batchInventories[0].inventory === null || a.batchInventories[0].inventory.article === null || a.batchInventories[0].inventory.article.nr === null) {
                        return 1;
                    }
                    if (!b.batchInventories || b.batchInventories.length === 0 || b.batchInventories[0] === null || b.batchInventories[0].inventory === null || b.batchInventories[0].inventory.article === null || b.batchInventories[0].inventory.article.nr === null) {
                        return -1;
                    }
                    return a.batchInventories[0].inventory.article.nr.localeCompare(b.batchInventories[0].inventory.article.nr);
                case "articleName":
                    if (!a.batchInventories || a.batchInventories.length === 0 || a.batchInventories[0] === null || a.batchInventories[0].inventory === null || a.batchInventories[0].inventory.article === null || a.batchInventories[0].inventory.article.name === null) {
                        return 1;
                    }
                    if (!b.batchInventories || b.batchInventories.length === 0 || b.batchInventories[0] === null || b.batchInventories[0].inventory === null || b.batchInventories[0].inventory.article === null || b.batchInventories[0].inventory.article.name === null) {
                        return -1;
                    }
                    return a.batchInventories[0].inventory.article.name.localeCompare(b.batchInventories[0].inventory.article.name);
                default:
                    return 0;
            }
        })
    }

    const blankBatch = {
        inventoryCountFree: 1,
        batchInventories:[
            {
                location:props.user.assignedLocation,
                inventoryCountFree: 0
            }
        ]
    }

    const POSITIONS_START_Y = 70;
    const LINE_HEIGHT = 8;
    const PAGE_LINES = 60;
    const LEFT_BORDER = 40;
    const EXPIRATION_DATE_X = 80;
    const ARTICLE_NR_X = 120;
    const ARTICLE_NAME_X = 160;
    const INVENTORY_COUNT_X = 400;
    const LINE_OFFSET = 4;

    const generatePDF = () => {
        if(batches && batches.length > 0) {
            let pdfBatches = batches;

            //format: ~445 x 630
            let pdf = new jsPDF('p', 'px');
            pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular-normal', 'normal');
            pdf.setFont('OpenSans-Regular');

            let availableLines = PAGE_LINES;
            let usedLines = 2;

            initializeCommonDocumentData(pdf,true);

            for(let i = 0; i < pdfBatches.length; i++) {
                // Add Header if needed
                if(availableLines - usedLines < 1 + pdfBatches[i].batchInventories?.length) {
                    initializeCommonDocumentData(pdf,false);
                    usedLines = 2;
                }

                pdf.text("___________________________________________________________________________________________________________________",LEFT_BORDER,POSITIONS_START_Y + (LINE_HEIGHT * usedLines) - LINE_OFFSET);
                usedLines++;

                let batchNr = pdfBatches[i].nr ? HelperFunctions.formatString(pdfBatches[i].nr.toString(),8) : "-";
                let expirationDate = pdfBatches[i].expirationDate ? helperFunctions.formatDate(pdfBatches[i].expirationDate).toString() : "-";

                pdf.text(batchNr,LEFT_BORDER,POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                pdf.text(expirationDate,EXPIRATION_DATE_X,POSITIONS_START_Y + LINE_HEIGHT * usedLines);

                if(pdfBatches[i].batchInventories) {
                    for(let j = 0; j < pdfBatches[i].batchInventories?.length; j++) {
                        let articleNr = pdfBatches[i].batchInventories && pdfBatches[i].batchInventories.length > 0 && pdfBatches[i].batchInventories[j].inventory && pdfBatches[i].batchInventories[j].inventory.article && pdfBatches[i].batchInventories[j].inventory.article.nr ? HelperFunctions.formatString(pdfBatches[i].batchInventories[0].inventory.article.nr.toString(),8) : "-";
                        let articleName = pdfBatches[i].batchInventories && pdfBatches[i].batchInventories.length > 0 && pdfBatches[i].batchInventories[j].inventory && pdfBatches[i].batchInventories[j].inventory.article && pdfBatches[i].batchInventories[j].inventory.article.name ? HelperFunctions.formatString(pdfBatches[i].batchInventories[0].inventory.article.name.toString(),50) : "-";
                        let inventoryCount = pdfBatches[i].batchInventories && pdfBatches[i].batchInventories.length > 0 && pdfBatches[i].batchInventories[j].inventoryCountFree ? HelperFunctions.formatString(pdfBatches[i].batchInventories[j].inventoryCountFree.toString(),8) : "-";

                        pdf.text(articleNr,ARTICLE_NR_X,POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                        pdf.text(articleName,ARTICLE_NAME_X,POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                        pdf.text(inventoryCount,INVENTORY_COUNT_X,POSITIONS_START_Y + LINE_HEIGHT * usedLines);

                        usedLines++;
                    }
                }

                usedLines++;
            }
            pdf.save("chargen_" + new Date().toISOString().substring(0,10));
        }else{
            props.showMessage(1,"Es werden keine Chargen angezeigt")
        }
    }

    const initializeCommonDocumentData = (pdf,firstPage) => {
        if(!firstPage) {
            pdf.addPage('string','p');
        }
        pdf.setTextColor("black");
        pdf.setFontSize(16);
        pdf.setFont('OpenSans-Bold','normal');
        pdf.text("Ablaufende Chargen",40,50);

        pdf.setFont('OpenSans-Regular','normal');
        pdf.setFontSize(10);

        pdf.text("Charge",LEFT_BORDER,POSITIONS_START_Y + LINE_HEIGHT);
        pdf.text("M.H.D.",EXPIRATION_DATE_X,POSITIONS_START_Y + LINE_HEIGHT);
        pdf.text("Artikelnr",ARTICLE_NR_X,POSITIONS_START_Y + LINE_HEIGHT);
        pdf.text("Artikelname.",ARTICLE_NAME_X,POSITIONS_START_Y + LINE_HEIGHT);
        pdf.text("Anzahl",INVENTORY_COUNT_X,POSITIONS_START_Y + LINE_HEIGHT);

        pdf.text(HelperFunctions.formatDate(new Date().toISOString()),540,50);

        pdf.text('Seite ' + 1,550,410);
    }

    const showBatchDialog = (batch) => {
        setBatch(batch);
        setBatchDialog(true);
    }

    const closeBatchDialog = () => {
        setBatchDialog(false);
        setBatch(null);
    }

    const showDeleteBatchDialog = (batch) => {
        setBatch(batch);
        setDeleteBatchDialog(true);
    }

    const closeDeleteBatchDialog = () => {
        setDeleteBatchDialog(false);
        setBatch(null);
    }

    return (
        <div className='batches'>

            <Tooltip id="batches-tooltip"/>

            <SecureYesCancelDialog
                open={deleteBatchDialog}
                close={closeDeleteBatchDialog}
                header='Löschen'
                text='Wollen Sie die Charge wirklich löschen?'
                onClick={() => deleteBatch(batch)}
                showMessage={props.showMessage}
            />

            {batch && batchDialog ? <BatchDialog
                open={batchDialog}
                close={closeBatchDialog}
                showMessage={props.showMessage}
                logout={props.logout}
                setLoading={props.setLoading}
                batches={props.batches}
                batch={batch}
                addBatchToList={addBatchToList}
                updateBatchInList={updateBatchInList}
                removeBatchFromList={removeBatchFromList}
                user={props.user}
                articles={props.articles}
                displayArticleInformation={props.displayArticleInformation}
                displayBatchInformation={props.displayBatchInformation}
                updateTransactionInList={props.updateTransactionInList}
            /> : null}

            <div className='header-bar'>
                <WarningAmberIcon className="icon warning-icon" onClick={() => loadUrgentBatches()}
                                  data-tooltip-id="batches-tooltip" data-tooltip-place="top"
                                  data-tooltip-content="Ablaufende Chargen anzeigen"/>
                <ToolTextField text='Suchwort' value={searchWord} onChange={(e) => setSearchWord(e.target.value)} onKeyDown={_handleKeyDown}
                           className='field search-input' label='Suchwort' size='small'/>
                <ToolTextField value={searchType} onChange={(e) => setSearchType(e.target.value)} size='small' select onKeyDown={_handleKeyDown}
                           className='field search-select' label='Suche'>
                    <MenuItem key={0} value={'free-search'}>Freie Suche</MenuItem>
                </ToolTextField>

                <ToolButton main className='search-button' onClick={searchOnClick}>
                    Suche
                </ToolButton>

                <FormGroup>
                    <FormControlLabel className="field checkbox-field" control={<Checkbox className="checkbox" checked={includeCompletedBatches} onChange={() => setIncludeCompletedBatches(!includeCompletedBatches)} />} label="auch abgeschlossene Chargen anzeigen" />
                </FormGroup>

                <ul className="sort-options">
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("nr")}
                               style={sort === "nr" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Chargennr.</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("expirationDate")}
                               style={sort === "expirationDate" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Min.-Halt.-Dat.</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("material")}
                               style={sort === "material" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Material</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("inventoryCountFree")}
                               style={sort === "inventoryCountFree" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Vorhanden</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("articleNr")}
                               style={sort === "articleNr" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Artikelnr.</Paper>
                    </li>
                    <li><Paper elevation={3} className="sort-option"
                               onClick={() => setSort("articleName")}
                               style={sort === "articleName" ? {
                                   backgroundColor: '#4D79FF',
                                   color: 'white'
                               } : null}>Artikelname</Paper>
                    </li>
                </ul>
            </div>

            <div className="item-list">
                {batches ? sortedBatches().map((batch) => (
                    <BatchItem
                        key={batch.id}
                        batch={batch}
                        showBatchDialog={showBatchDialog}
                        deleteBatch={showDeleteBatchDialog}
                        setLoading={props.setLoading}
                        logout={props.logout}
                        showMessage={props.showMessage}
                    />)) : null}
            </div>

            {false ? <ToolFab onClick={() => showBatchDialog(blankBatch)} className='add-fab' aria-label="generate">
                <AddIcon/>
            </ToolFab> : null}

            <ToolFab onClick={generatePDF} className='pdf-fab' aria-label="download-as-pdf">
                <PictureAsPdfIcon/>
            </ToolFab>
        </div>
    )
}

export default Batches;