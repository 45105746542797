import React, {Component} from "react";
import './Login.css';

//Components
import ToolButton from "../../reusable/ToolButton/ToolButton";
import logo from './../../images/logo.png';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import ToolPaper from "../../reusable/ToolPaper/ToolPaper";

class Login extends Component {

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.loginClick();
        }
    }

    render() {
        return (
            <div onKeyDown={this._handleKeyDown} className='login primary-color-background-very-light'>
                <ToolPaper className='login-div white-background'>
                    <img className='wm-logo' src={logo} alt="Warehouse Manager 4.0 Logo"/>
                    <div className='input-div box-shadow-dark'>
                        <BusinessIcon className='input-icon'/>
                        <input className='input-field' placeholder='Unternehmenskürzel (5-stellig)'
                               onChange={this.props.loginAbbreviationChangeHandler}
                               defaultValue={localStorage.getItem('abbreviation')}/><br/>
                    </div>
                    <div className='input-div box-shadow-dark'>
                        <PersonIcon className='input-icon'/>
                        <input className='input-field' placeholder='Benutzername'
                               onChange={this.props.loginUsernameChangeHandler}/><br/>
                    </div>
                    <div className='input-div box-shadow-dark'>
                        <LockIcon className='input-icon'/>
                        <input className='input-field' placeholder='Passwort' type='password'
                               onChange={this.props.loginPasswordChangeHandler}/><br/>
                    </div>
                    <a href='https://www.panthera-software.com/loesungen/warehouse-manager#call-to-action'
                       target='_blank' rel='noopener noreferrer' className='register-text'>Unternehmensaccount
                        registrieren</a>
                    <ToolButton onClick={this.props.loginClick} main className='login-button'>Anmelden</ToolButton>
                </ToolPaper>
                <a className="company-website" href="https://www.panthera-software.com" target="_blank"
                   rel="noopener noreferrer">2025 Panthera Software Solutions GmbH</a>
            </div>
        )
    }
}

export default Login;