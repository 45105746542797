import React, { useState } from "react";
import './ArticleInformation.css';

//Component
import HelperFunctions from "../../../../../reusable/HelperFunctions";
import ToolPaper from "../../../../../reusable/ToolPaper/ToolPaper";
import {Tooltip} from "react-tooltip";

const ArticleInformation = (props) => {

    let articleInformation = null;
    let batchInformation = null;

    if(props.articleInformation !== null && props.articleInformation.inventoryCount !== null) {
        articleInformation = <div className="fields-holder">

            <div className='tool-text-pair big-field'>
                <p className='text'>Artikelnummer</p>
                <p className='value' style={props.style}
                   data-tooltip-id="article-information-tooltip" data-tooltip-place="top"
                   data-tooltip-content={props.articleInformation.article.nr && props.articleInformation.article.nr.length > 28 ? props.articleInformation.article.nr : ""}>{props.articleInformation.article.nr ? HelperFunctions.formatString(props.articleInformation.article.nr, 28) : "-"}</p>
            </div>

            <div className='tool-text-pair big-field'>
                <p className='text'>Artikelnummer</p>
                <p className='value' style={props.style}
                   data-tooltip-id="article-information-tooltip" data-tooltip-place="top"
                   data-tooltip-content={props.articleInformation.article.name && props.articleInformation.article.name.length > 28 ? props.articleInformation.article.name : ""}>{props.articleInformation.article.name ? HelperFunctions.formatString(props.articleInformation.article.name, 28) : "-"}</p>
            </div>

            <div className='tool-text-pair field'>
                <p className='text'>Lagerbestand</p>
                <p className='value'
                   style={props.articleInformation.inventoryCount >= 0 ? null : {color: "lightcoral"}}>{props.articleInformation.inventoryCount}</p>
            </div>

            <div className='tool-text-pair field'>
                <p className='text'>Mindestbestand</p>
                <p className='value'
                   style={props.articleInformation.article.minimumStock ? props.articleInformation.inventoryCount >= props.articleInformation.article.minimumStock ? null : {color: "lightcoral"} : null}>{props.articleInformation.article.minimumStock ? props.articleInformation.article.minimumStock : "-"}</p>
            </div>

            <div className='tool-text-pair field'>
                <p className='text'>Frei</p>
                <p className='value'
                   style={(props.articleInformation.inventoryCount - props.articleInformation.reservationCount) >= 0 ? null : {color: "lightcoral"}}>{props.articleInformation.inventoryCount - props.articleInformation.reservationCount}</p>
            </div>
        </div>
    }
    if (props.articleInformation && props.batchInformation) {
        batchInformation = <div className="batches-holder">
            {props.batchInformation ? props.batchInformation.map(batchInformation => (
                batchInformation.batchInventories ? batchInformation.batchInventories.map(batchInventory => (
                    batchInventory?.inventory?.article?.id === props.articleInformation?.article?.id ?
                        <ToolPaper elevation={3}
                                   className={['batch-holder', props.blinkBatch?.id === batchInformation.id ? "blink" : null].join(' ')}>
                            <div className='tool-text-pair big-field'>
                                <p className='text'>Chargennummer</p>
                                <p className='value' style={props.style}
                                   data-tooltip-id="article-information-tooltip" data-tooltip-place="top"
                                   data-tooltip-content={batchInformation.nr && batchInformation.nr.length > 28 ? batchInformation.nr : ""}>{batchInformation.nr ? batchInformation.nr : "-"}</p>
                            </div>

                            <div className='tool-text-pair big-field'>
                            <p className='text'>Mindesthaltbarkeitsdatum</p>
                                <p className='value'>{batchInformation.expirationDate ? HelperFunctions.formatDate(batchInformation.expirationDate) : "-"}</p>
                            </div>

                            <div className='tool-text-pair field'>
                                <p className='text'>Lagerbestand</p>
                                <p className='value' style={(batchInventory.inventoryCountFree) >= 0 ? null : {color: "lightcoral"}}>{batchInventory.inventoryCountFree}</p>
                            </div>
                        </ToolPaper> : null
                )) : null
            )) : null}
        </div>
    }

    return (
        <div className='article-information main-panel white-background'>
            <Tooltip id="article-information-tooltip"/>
            {articleInformation}
            {batchInformation}
        </div>
    )
}

export default ArticleInformation;