import React, { useState, useEffect, useRef } from 'react';
import './TransactionDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import { Tooltip } from 'react-tooltip'
import HelperFunctions from "../HelperFunctions";
import ToolButton from "../ToolButton/ToolButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import api from "../../api";

const TransactionDialog = (props) => {

    const [edit, setEdit] = useState(false);
    const [transaction, setTransaction] = useState(props.transaction)

    const toggleEdit = () => {
        setEdit(!edit);
    }

    const inventoryCountOnChangeHandler = (e) => {
        let updatedTransaction = { ...transaction };
        updatedTransaction.inventoryCount = e.target.value;
        setTransaction(updatedTransaction);
    }

    const updateTransactionOnClickHandler = () => {
        if(transaction && transaction.inventoryCount !== null) {
            updateTransaction();
        }
    }

    const updateTransaction = () => {
        api.put("/transactions",transaction)
            .then(response => {
                props.updateTransactionInList(transaction);
                props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                props.close();
                props.displayArticleInformation(null);
                props.displayBatchInformation(null);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.setLoading(false);
                }
                props.setLoading(false);
            });
    }

    return (
        <Dialog scroll='body' maxWidth={"lg"} open={props.open} onClose={props.close} className='transaction-dialog'>
            <Tooltip id="transaction-dialog-tooltip"/>
            <div className="surrounder">
                <h1>Transaktionsdetails</h1>
                <FormControlLabel
                    className='switch'
                    control={
                        <Switch
                            checked={edit}
                            onChange={toggleEdit}
                            color="primary"
                        />
                    }
                    label="Bearbeitung"/>
                <p>Chargennummer: {transaction.batch && transaction.batch.nr ? transaction.batch.nr : "-"}</p>
                <p>Mindesthaltbarkeitsdatum: {transaction.batch && transaction.batch.expirationDate ? HelperFunctions.formatDate(transaction.batch.expirationDate) : "-"}</p>
                <p>Artikelnummer: {transaction.article.nr ? transaction.article.nr : "-"}</p>
                <p>Artikelname: {transaction.article.name ? transaction.article.name : "-"}</p>
                <p>Anzahl: {edit ? <input className="count-input" type="number" onChange={inventoryCountOnChangeHandler} value={transaction.inventoryCount}/> : transaction.inventoryCount ? transaction.inventoryCount : "-"}</p>
                <p>Zeitpunkt: {transaction.dateTime ? HelperFunctions.formatDateTime(transaction.dateTime) : "-"}</p>
                <p>Benutzer: {transaction.user ? transaction.user.username : "-"}</p>
                <p>Standort
                    (von): {transaction.fromLocation && transaction.fromLocation.name ? transaction.fromLocation.name : "-"}</p>
                <p>Standort
                    (nach): {transaction.toLocation && transaction.toLocation.name ? transaction.toLocation.name : "-"}</p>
                <p>Kunde: {transaction.assignedCustomer && transaction.assignedCustomer.name ? transaction.assignedCustomer.name : "-"}</p>
                <p>Lieferant: {transaction.assignedSupplier && transaction.assignedSupplier.name ? transaction.assignedSupplier.name : "-"}</p>
                <ToolButton main className="save-button" onClick={updateTransactionOnClickHandler}>Speichern</ToolButton>
            </div>
        </Dialog>
    )
}

export default TransactionDialog;