import React, {Component} from "react";
import './SecureYesCancelDialog.css';

//Components
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ToolTextField from "../ToolField/ToolTextField";
import ToolButton from "../ToolButton/ToolButton";

class SecureYesCancelDialog extends Component {

    state = {
        deleteText:""
    }

    onClick = () => {
        if(this.state.deleteText === "Löschen") {
            this.props.onClick();
            this.close();
        }else{
            this.props.showMessage(2,"Geben Sie \"Löschen\" in das Textfeld ein, um die Aktion unwiederruflich durchzuführen.");
        }
    }

    deleteTextOnChangeHandler = (e) => {
        this.setState({deleteText:e.target.value});
    }

    close = () => {
        this.setState({deleteText:""});
        this.props.close();
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.close} className='secure-yes-cancel-dialog'>
                <DialogTitle>{this.props.header}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{this.props.text}</DialogContentText>
                    <p>Geben Sie "Löschen" in das Textfeld ein, um die Aktion unwiederruflich durchzuführen.</p>
                    <ToolTextField size="small" placeholder="Löschen" onChange={this.deleteTextOnChangeHandler}/>
                </DialogContent>
                <DialogActions>
                    <ToolButton autoFocus onClick={this.close}>
                        <b>Abbrechen</b>
                    </ToolButton>
                    <ToolButton main onClick={this.onClick} color="primary">
                        <b>Ja</b>
                    </ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default SecureYesCancelDialog;