import React, {Component} from "react";
import './UserMenu.css';
import WarehouseDashboard from "../../WarehouseManagementSystem/WarehouseActions/WarehouseDashboard/WarehouseDashboard";
import {Route} from "react-router-dom";

//Components

class UserMenu extends Component {

    state = {
        showMenuPanel:false
    }

    showSettings = () => {
        this.toggleMenuPanel();
        this.props.showSettings();
    }

    toggleMenuPanel = () => {
        this.setState({showMenuPanel:!this.state.showMenuPanel});
    }

    render() {
        let menuPanel = null;
        if(this.state.showMenuPanel) {
            menuPanel =
                <ul className='MenuPanel'>
                    <li onClick={this.props.logout}>Logout</li>
                </ul>
        }

        return (
            <div className='UserMenu primary-color-background-very-light'>
                <Route exact path='/warehouse-manager'>
                    <h1 className="active-display">Übersicht</h1>
                </Route>
                <Route exact path='/*/order-management'>
                    <h1 className="active-display">Bestellwesen</h1>
                </Route>
                <Route exact path='/*/inventory'>
                    <h1 className="active-display">Inventar</h1>
                </Route>
                <Route exact path='/*/communication'>
                    <h1 className="active-display">Kommunikation</h1>
                </Route>
                <Route exact path='/*/reporting'>
                    <h1 className="active-display">Reporting</h1>
                </Route>
                <Route exact path='/*/master-data'>
                    <h1 className="active-display">Stammdaten</h1>
                </Route>
                <Route exact path='/*/administration'>
                    <h1 className="active-display">Administration</h1>
                </Route>
                <Route exact path='/*/settings'>
                    <h1 className="active-display">Einstellungen</h1>
                </Route>
                <h2 className='UsernameText' onClick={this.toggleMenuPanel}>{this.props.user ? this.props.user.username : 'Lädt...'}</h2>
                {menuPanel}
            </div>
        )
    }
}

export default UserMenu;