import React, {Component} from 'react';
import './LoadingBackdrop.css';

//Components
import CircularProgress from '@mui/material/CircularProgress';

class LoadingBackdrop extends Component {

    render() {
        return (
            <div className="loading-backdrop primary-color-background-very-light">
                <div className="loading-pulse"/>
            </div>
        )
    }
}

export default LoadingBackdrop;