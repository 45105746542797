import React, {Component} from "react";
import './InputField.css';
import TextField from "@mui/material/TextField";

class ToolField extends Component {
    render() {
        return (
            <TextField className={['tool-field', this.props.className].join(' ')} value={this.props.value} label={this.props.label} onChange={this.props.onChange} disabled={this.props.disabled}>
                {this.props.children}
            </TextField>
        )
    }
}

export default ToolField;