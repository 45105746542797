import React, {Component} from "react";
import "./ToolFab.css"

//Components
import Fab from "@mui/material/Fab";

class ToolFab extends Component {

    state = {
        hovered:false
    }

    hoverOn = () => {
        this.setState({hovered:true});
    }

    hoverOff = () => {
        this.setState({hovered:false});
    }

    render() {

        return (
            <Fab onClick={this.props.onClick} props={this.props} color='primary' className={['tool-fab', this.props.className].join(' ')}>
                {this.props.children}
            </Fab>
        )
    }
}

export default ToolFab;