import React, {Component, useState} from "react";
import "./ToolPaper.css";
import Paper from "@mui/material/Paper";

//Components

const ToolPaper = (props) => {

    return (
        <Paper { ...props } className={['tool-paper white-background black', props.className].join(' ')} onClick={props.onClick}>
            {props.children}
        </Paper>
    )
}

export default ToolPaper;